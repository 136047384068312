import firebase from 'firebase';

import { createContext, useEffect, useState } from "react";

import { auth, database } from "../services/firebase";

import Factory from '../services/factory';
import Firestore from '../services/firestore';

import { useAppSelector } from '../../utils/useStore';

import { selectApplication } from '../slice/application';

import { IUser } from '../models/model';

export const UserContext = createContext<{ user : IUser | null, setUser : Function }>({ user : null, setUser : () => {} });

function UserProvider(props : any)
{
    const [user, setUser] = useState<IUser | null>(null);

    const application = useAppSelector(selectApplication);

    useEffect(() => {

        const unsubscibe = auth.onAuthStateChanged(function (current)
        {
            current ? getUserDocument(current) : setUser(null);
        });

        return () => { unsubscibe() }

    }, []);

    useEffect(() => {

        if (application.logged === false)
        {
            return;
        }

        const current = firebase.auth().currentUser;

        if (current === null)
        {
            return;
        }

        getUserDocument(firebase.auth().currentUser!);

    }, [application.logged]);

    useEffect(() => {

        if (user === null)
        {
            return;
        }

        const unsubscibe = database.collection('users').doc(user.id).onSnapshot(function(snapshot)
        {
            const data = snapshot.data();

            setUser(Factory.createUserFromFirebase({ ...data, id : snapshot.id }));
        });

        return () => { unsubscibe() }

    }, [user]);

    async function getUserDocument(current : firebase.User)
    {
        const document = await Firestore.getUserDocumentFromFirestore(current.uid);
    
        setUser(Factory.createUserFromFirebase({ ...document.data(), id : document.id }));
    }

    return (
        <UserContext.Provider value={{user, setUser }}>
            { props.children }
        </UserContext.Provider>
    );
}

export default UserProvider;