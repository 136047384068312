import Button from "../../../components/button/button";
import Input from "../../../components/input/input";

import HeaderSettingsView from "./header";

import useForm from "../../../utils/useForm";

import { IProfileViewProps } from "./profile";

import { useContext, useState } from "react";

import firebase from "firebase";

import { UserContext } from "../../../core/providers/user";

import Eye from '@material-ui/icons/Visibility';

import { useToasts } from 'react-toast-notifications';

const PasswordProfile = function({ setStep } : IProfileViewProps) : any
{
    const { inputs, setInput } = useForm({ current : "", new : "", confirm : "" });

    const [loading, setLoading] = useState<boolean>(false);

    const { user } = useContext(UserContext);

    const { addToast } = useToasts();

    const reauthenticate = async function()
    {
        const currentUser = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(user!.email, inputs.current);

        return currentUser?.reauthenticateWithCredential(cred);
      }

    const handlePasswordChangeEvent = async function() : Promise<void>
    {
        if (inputs.current === "" || inputs.new === "" || inputs.confirm === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        if (inputs.new !== inputs.confirm)
        {
            addToast("les mots de passe ne correspondent pas.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setLoading(true);

        try
        {
            await reauthenticate();

            await firebase.auth().currentUser!.updatePassword(inputs.confirm);

            addToast('Votre mot de passe a bien été changé.', { appearance: 'success', autoDismiss : true });
        }
        catch (error)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }
    
    return (
        <div className="profile-menu-view">
            <HeaderSettingsView title="Mot de passe" setStep={setStep} />
            <div className="profile-business invisible-scrollbar">
                <Input title="Mot de passe actuel" value={inputs.current} onChanged={setInput} name="current" type="password" icon={<Eye />} />
                <Input title="Nouveau mot de passe" value={inputs.new} onChanged={setInput} name="new" type="password" icon={<Eye />} />
                <Input title="Confirmation mot de passe" value={inputs.confirm} onChanged={setInput} name="confirm" type="password" icon={<Eye />} />
                <Button title={ loading ? "Chargement..." : "Sauvegarder"} fill={true} onClick={ () => handlePasswordChangeEvent() } />
            </div>
        </div>
    );
}

export default PasswordProfile;