import './view.css';

import { useContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Firestore from '../../../core/services/firestore';

import { useAppDispatch } from '../../../utils/useStore';
import { setApplicationModal } from '../../../core/slice/application';

import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';

import { IAd, IUser } from '../../../core/models/model';

import Grade from '../../../components/grade/grade';

import { UserContext } from '../../../core/providers/user';
import { database } from '../../../core/services/firebase';
import firebase from 'firebase';
import { useToasts } from 'react-toast-notifications';
import Factory from '../../../core/services/factory';
import CommentList from '../../../components/ComentList/commentList';
import Realisation from '../../../components/realisation/realisation';
import { IReportType } from '../../../modals/report/report';

function View()
{
    const { id } = useParams<{ id : string }>();

    const [data, setData] = useState<IAd | null>(null);
    const [parent, setParent] = useState<IUser | null>(null);

    const dispatch = useAppDispatch();

    const history = useHistory();

    const { user } = useContext(UserContext);

    const { addToast } = useToasts();

    useEffect(() => 
    {
        const fetch = async function() : Promise<void>
        {
            dispatch(setApplicationModal({ index : 1, props : null }));

            const annonce : IAd | null = await Firestore.getAdsByIdFromFirestore(id);

            const owner = await Firestore.getUserDocumentFromFirestore(annonce!.createdBy);

            setParent(Factory.createUserFromFirebase({ ...owner.data(), id : owner!.id }));
            setData(annonce);

            if (user !== null && user!.id !== owner.id)
            {
                await database.collection('annonces').doc(id).update({ views : firebase.firestore.FieldValue.increment(1) });
            }

            dispatch(setApplicationModal({ index : null, props : null }));
        }

        fetch();

    }, []);

    const handleReportEvent= function() : void
    {
        dispatch(setApplicationModal({ index : 0, props : { document : data!.id, title : "Signaler une annonce", type : IReportType.AD } }));
    }

    const handleGoBackEvent = function() : void
    {
        history.goBack();
    }

    const handleSpeackEvent = async function()
    {
        if (data == null || user === null || parent === null || data.createdBy === user!.id)
        {
            return;
        }

        try
        {
            const document = await database.collection('channels').where('annonce.id', "==", data.id).where('parent', "==", user!.id).get();

            if (document.docs.length > 0)
            {
                await database.collection('channels').doc(document.docs[0].id).update({ date : firebase.firestore.Timestamp.fromDate(new Date()) });
                
                history.push('/home/messages');

                return;
            }

            const information = {
                parent : user!.id,
                users : [user!.id, data.createdBy],
                name : parent!.name,
                last : "",
                enable : true,
                avatar : parent!.avatar,
                annonce : { image : data.image, title : data.title, description : data.description, id : data.id, hidePhoneNumber : data.hidePhoneNumber },
                date : firebase.firestore.Timestamp.fromDate(new Date()),
                block : false
            };

            const newChanel = await database.collection('channels').add(information);

            await database.collection('annonces').doc(id).update({ messages : firebase.firestore.FieldValue.increment(1) });
            await database.collection('channels').doc(newChanel.id).collection("typings").doc("typings").set({ typings : [] });

            history.push('/home/messages');
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }
    }

    return (
        <div className="view-wrapper">
            <div className="view-header">
                <button onClick={ () => handleGoBackEvent() } ><ArrowLeft /></button>
                <p>Retour</p>
            </div>
            <div className="view-ad-content">
                <div>
                    { data && <img src={ data.image } alt="ad show" /> }
                </div>
                <div className="ads-information-wrapperr">
                    <div className="view-information">
                        <div className="view-profile">
                            <div>
                                { parent && <img src={parent.avatar} alt="owner profile" /> }
                            </div>
                            <div>
                                { parent && <p>{ parent.name }</p> }
                                <div>
                                    { (data && data.grade !== 0) && <Grade grade={data.grade} /> }
                                    { data && <p onClick={ () => {} }>{`Voir les avis (${ data.avis })`}</p> }
                                </div>
                            </div>
                        </div>
                        <div className="text-info-wrapper">
                            { data && <p>{data.title }</p> }
                            { data && <p>{data.description }</p> }
                        </div>
                        <div className="ads-button-wrapper">
                            <button onClick={ () => handleSpeackEvent() } style={{ backgroundColor : (data && user !== null && data.createdBy !== user!.id) ? "#F98D1F" : "#C2C3C2" }} className="chat-button">
                                Envoyer un message
                            </button>
                        </div>
                        <div className="view-report-button-wrapper">
                            { (data && user && data.createdBy !== user!.id) && <button onClick={ () => handleReportEvent() } className="report-button">
                                Signaler l’annonce
                            </button> }
                        </div>
                    </div>
                </div>
            </div>
            <div className="view-description">
                <p>Description complète</p>
                { data && <p>{ data.description }</p> }
            </div>
            <div className="view-table">
                <p>Infos supplémentaires</p>
                <div>
                    <div>
                        <p>Prix de l’annonce</p>
                        { (data && data.price) ? <p>{ data.price }</p> : <p>Aucun prix n’a été annoncé pour cette annonce, <span id="view-span-message" onClick={ () => handleSpeackEvent() }>contactez l’artisan</span></p> }
                    </div>
                    <div>
                        <p>Rayon de déplacement maximum</p>
                       { data && <p>{`L’artisan se déplace dans un périmètre ${ data!.rayon.value }.`}</p> }
                    </div>
                    <div>
                        <p>Secteur d’activité</p>
                        <p>Peinture extérieur</p>
                    </div>
                    <div>
                        <p>Nombre de visites</p>
                        { data && <p>{`Cette annonce à été visitée ${ data.views } fois`}</p> }
                    </div>
                </div>
            </div>
            { (data && parent && data.makes.length !== 0) && <Realisation ad={data} parent={parent} /> }
            { data && <CommentList ad={data} /> }
        </div>
    );
}

export default View;