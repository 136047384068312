import './login.css';

import { useState } from 'react';

import { useHistory } from 'react-router';

import useAuth from '../../../utils/useAuth';
import useForm from '../../../utils/useForm';

import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import Image from '../../../assets/auth/login.png';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';

function Login()
{
    const [loading, setLoading] = useState(false);

    const { login } = useAuth();

    const { inputs, setInput } = useForm({ email : "", password : "" });

    const history = useHistory();

    const handleSubmitEvent = async function()
    {
        setLoading(true);

        await login(inputs.email, inputs.password);

        setLoading(false);
    }

    const handleRegisterEvent = function()
    {
        history.push('/auth/register');
    }

    const handleResetEvent = function()
    {
        history.push('/auth/reset');
    }

    const handleCloseEvent = function() : void
    {
        history.push('/home/search');
    }

    return (
        <div className="login-view-wrapper">
            <div className="auth-image-wrapper">
                <img src={Image} alt="login" />
            </div>
            <div className="auth-right-scroll-wrapper">
                <div className="auth-right-wrapper">
                    <div className="auth-header-wrapper">
                        <p>VOUS REVOILÀ !</p>
                        <p>Connectez-vous</p>
                        <Close onClick={handleCloseEvent} />
                    </div>
                    <div className="auth-form-wrapper">
                        <Input name="email" title="Adresse email" value={inputs.email} onChanged={setInput} placeholder="john.doe@gmail.com" />
                        <Input name="password" title="Mot de passe" value={inputs.password} onChanged={setInput} placeholder="Mot de passe" type="password" />
                        <a onClick={handleResetEvent}>Mot de passe oublié ?</a>
                        <Button title="Connexion" fill={true} onClick={handleSubmitEvent} loading={loading} />
                    </div>
                    <div className="login-register-wrapper" onClick={handleRegisterEvent}>
                        <p>Vous souhaitez vous inscrire ?</p>
                        <p>Afin d’envoyer des messages à des annonceurs ou publiez vos annonces.</p>
                        <p>S’inscrire maintenant</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;