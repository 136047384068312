import './menuItem.css';

import { useAppDispatch, useAppSelector } from '../../utils/useStore';

import { selectApplicationProfile, setApplicationMenuIndex } from '../../core/slice/application';

interface IMenuItemProps
{
    item : { name : string, to : number }
    setStep : Function
}

function MenuItem({ item, setStep } : IMenuItemProps)
{
    const application = useAppSelector(selectApplicationProfile);

    const dispatch = useAppDispatch();

    const isActive = item.to === application.index ? "menu-item-wrapper-active" : "menu-item-wrapper-not-active";

    const handleGoToEvent = function() : void
    {
        setStep(item.to);

        dispatch(setApplicationMenuIndex(item.to));
    }

    return (
        <div className={`menu-item-wrapper ${isActive}`} onClick={ () => handleGoToEvent() }>
            <p>{ item.name }</p>
        </div>
    );
}

export default MenuItem;