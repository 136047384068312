import { configureStore } from '@reduxjs/toolkit';

import { searchSlice } from '../slice/search';
import { applicationSlice } from '../slice/application';

import { IAd } from '../models/model';

export const store = configureStore({
    reducer : {
        search : searchSlice.reducer,
        application : applicationSlice.reducer
    }
});

// Application //

export interface ApplicationState
{
    profile : ProfileState
    settings : SettingsState
    modal : ModalState
    logged : boolean
}

// Profile //

export interface ProfileState
{
    index : number
}

// Settings //

export interface SettingsState
{
    
}

// Modal //

export interface ModalState
{
    index : number | null
    props : any
}

// Search //

export interface SearchState
{
    typesMaterials : IAd[] | null
    typesHumans : IAd[] | null
    myAds : { enable : IAd[] | null, disable : IAd[] | null }
}

// Root //

export interface IRootState
{
    search : SearchState
    application : ApplicationState
}

export type AppDispatch = typeof store.dispatch;