import './card.css';

import { useContext, useState } from 'react';

import useModal from '../../utils/useModal';

import { ReactComponent as Close } from '../../assets/icons/close.svg';

import { setApplicationModal } from '../../core/slice/application';

import { useAppDispatch } from '../../utils/useStore';

import Button from '../../components/button/button';
import { UserContext } from '../../core/providers/user';
import firebase from 'firebase';

function CardPayement()
{
    const [loading, setLoading] = useState<boolean>(false);

    const { user } = useContext(UserContext);

    const { node } = useModal();

    const dispatch = useAppDispatch();

    const handleCloseEvent = function (): void
    {
        dispatch(setApplicationModal({ index: null, props: null }));
    }

    const handleManageBilling = function (): void
    {
        var test = firebase.functions().httpsCallable('updateCardToStripeCustomer');

        test({ customerID : user!.customerID, url : "http://localhost:3000/home/profile" }).then((result: any) => { window.location.href = result.data });
    }

    return (
        <div className="modal-wrapper">
            <div className="card-payement-modal-wrapper" ref={node}>
                <div>
                    <p>Moyen de paiement</p>
                    <Close onClick={() => handleCloseEvent()} />
                </div>
                <div>
                    <Button title="Manage card" fill={false} onClick={() => handleManageBilling()} />
                    <Button title="Annuler" fill={false} onClick={() => handleCloseEvent()} />
                </div>
            </div>
        </div>
    );
}

export default CardPayement;

