import './type.css';

import { useHistory } from 'react-router-dom';

import { IAd } from '../../core/models/model';

interface ITypeProps
{
    type : IAd
    last : boolean
}

function Type({ type, last } : ITypeProps)
{
    const history = useHistory();

    const handleClickEvent = function()
    {
        history.push(`/home/search/${"type"}/ads/${type.id}`);

        window.scrollTo({ top : 0, behavior : 'smooth' });
    }

    return (
        <div className={`type-wrapper ${ last === true && "active-type"}`} onClick={ () => handleClickEvent() }>
            <img src={ type.image } alt="type" />
            <div>
                <p>{ type.title }</p>
                <div className="button-wrapper-type">
                    <button>
                        Voir l'annonce
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Type;