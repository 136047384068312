import './chatInfo.css';

import { useContext, useEffect, useState } from 'react';

import { useAppDispatch } from '../../utils/useStore';

import { setApplicationModal } from '../../core/slice/application';

import { useHistory } from 'react-router-dom';

import { ReactComponent as Arrow } from '../../assets/icons/arrow-left.svg';

import { IChatStepView } from '../../views/home/messages/messages';
import { IChannel, IUser } from '../../core/models/model';
import { IReportType } from '../../modals/report/report';
import { database } from '../../core/services/firebase';
import Firestore from '../../core/services/firestore';
import Factory from '../../core/services/factory';
import { UserContext } from '../../core/providers/user';

interface IChatInfoProps
{
    channel : IChannel | null
    goBack : Function
}

const ChatInfo = function({ channel, goBack } : IChatInfoProps) : JSX.Element
{
    const [visible, setVisible] = useState<boolean>(false);
    
    const dispatch = useAppDispatch();
    
    const history = useHistory();

    const [parent, setParent] = useState<IUser | null>(null);

    const { user } = useContext(UserContext);

    useEffect(() => {

        const fetchUserCompanie = async function() : Promise<void>
        {
            if (channel === null)
            {
                return;
            }

            console.log(channel!.to);

            const findMe = channel.users.filter(uid => uid !== user!.id);

            const tmp = await Firestore.getUserDocumentFromFirestore(findMe[0]);

            setParent(Factory.createUserFromFirebase({ ...tmp.data(), id : findMe[0] }));
        }

        fetchUserCompanie();

    }, [channel]);

    const handleSeeNumberEvent = function() : void
    {
        setVisible(!visible);
    }

    const handleReportEvent = function() : void
    {
        dispatch(setApplicationModal({ index : 0, props : { document : channel!.parent, title : "Signaler une annonce", type : IReportType.AD } }));
    }

    const handleSeeAdsEvent = function() : void
    {
        if (channel === null)
        {
            return;
        }

        history.push(`/home/search/type/ads/${ channel!.annonce!.id }`);

        window.scrollTo({ top : 0, behavior : 'smooth' });
    }

    if (channel === null)
    {
        return <div className="message-information"></div>
    }

    return (
        <div className="message-information">
            <div className="information-top">
                <div>
                    <p>L'annonce</p>
                    <button onClick={ () => goBack(IChatStepView.MESSAGE) }>
                        <Arrow />
                    </button>
                </div>
                <img src={channel.annonce!.image} alt="annonce" />
                <p>{channel.annonce!.title}</p>
                <p>{channel.annonce!.description}</p>
                <button onClick={ () => handleSeeAdsEvent() }>
                    Voir l’annonce complète
                </button>
            </div>
            <div className="information-bottom">
                { !channel.annonce?.hidePhoneNumber && <button onClick={ () => handleSeeNumberEvent() }>
                    { visible ? parent?.companie?.phone : "Voir le numéro" }
                </button> }
                <p onClick={ () => handleReportEvent() }>Signaler</p>
            </div>
        </div>
    );
}

export default ChatInfo;