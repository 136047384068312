import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { auth, database, getFirebaseErrorFromCode } from "../core/services/firebase";

import Firestore from '../core/services/firestore';
import Storage from "../core/services/storage";

import { setApplicationLogged } from "../core/slice/application";

import { useAppDispatch } from "./useStore";

interface IInformation
{
    name : string
    email : string
    password : string
    image : any
}

function useAuth()
{
    // state //

    const { addToast } = useToasts();

    const dispatch = useAppDispatch();

    const history = useHistory();

    // login to firebase auth //

    const login = async function(email : string, password : string) : Promise<void>
    {
        try
        {
            await auth.signInWithEmailAndPassword(email, password);

            dispatch(setApplicationLogged(false));
        }
        catch (error : any)
        {
            addToast(getFirebaseErrorFromCode(error.code), { appearance: 'error', autoDismiss : true });
        }
    }

    // register to firebase auth //

    const register = async function(information : IInformation, file : string) : Promise<void>
    {
        try
        {
            dispatch(setApplicationLogged(false));

            const user = await auth.createUserWithEmailAndPassword(information.email, information.password);

            const url = await Storage.addToStorage(`users/${user.user!.uid}/`, file);

            await Firestore.createUserDocumentToFirestore(user.user!.uid, information, url);

            dispatch(setApplicationLogged(true));
        }
        catch (error : any)
        {
            addToast(getFirebaseErrorFromCode(error.code), { appearance: 'error', autoDismiss : true });
        }
    }

    // logout from firebase auth //

    const logout = async function() : Promise<void>
    {
        try
        {
            await auth.signOut();
        }
        catch (error : any)
        {
            addToast(getFirebaseErrorFromCode(error.code), { appearance: 'error', autoDismiss : true });
        }
    }

    // reset firebase password //

    const reset = async function(email : string) : Promise<void>
    {
        try
        {
            const document = await database.collection('users').where('email', "==", email).get();

            if (document.docs.length <= 0)
            {
                addToast('Aucun compte associé à l’adresse électronique.', { appearance: 'error', autoDismiss : true });

                return;
            }

            firebase.auth().sendPasswordResetEmail(email);

            addToast('Un e-mail vous a été envoyé.', { appearance: 'success', autoDismiss : true });

            history.push("/auth/login");
        }
        catch (error : any)
        {
            addToast(getFirebaseErrorFromCode(error.code), { appearance: 'error', autoDismiss : true });
        }
    }

    return { login, register, logout, reset }
}

export default useAuth;