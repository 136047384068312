import { useContext, useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { IChannel, IMessage } from "../core/models/model";

import { UserContext } from "../core/providers/user";

import Factory from "../core/services/factory";

import { database } from "../core/services/firebase";

function useChat(channel : IChannel | null) : { messages : IMessage[], channels : IChannel[] }
{
    // state //

    const [messages, setMessages] = useState<IMessage[]>([]);

    const [channels, setChannels] = useState<IChannel[]>([]);

    const ref = useRef<IChannel[]>([]);

    const { user } = useContext(UserContext);

    // listner on chat messages //

    useEffect(() => {

        setMessages([]);

        if (channel === null)
        {
            return;
        }

        const unsubscribe = database.collection('channels').doc(channel.id).collection('chat').orderBy("date", "asc").onSnapshot(function(snapshot)
        {
            snapshot.docChanges().forEach(function(change)
            {
                if (change.type === "added")
                {
                    var message : IMessage | null = Factory.createMessageFromFirebase(change.doc.data(), change.doc.id);

                    setMessages((messages) => [...messages, message!]);
                }

                if (change.type === "removed")
                {
                    setMessages((messages) => messages.filter(message => message.id !== change.doc.id));
                }
            })
        });

        return () => { unsubscribe(); }

    }, [channel]);

    useEffect(() => {

        const unsubscribe = database.collection('channels').where('users', "array-contains", user!.id).orderBy("date", "desc").onSnapshot(function(snapshot)
        {
            snapshot.docChanges().forEach(function(change)
            {
                if (change.type === "added")
                {
                    var conv : IChannel | null = Factory.createChannelFromFirebase(change.doc.data(), change.doc.id);

                    if (conv === null)
                    {
                        return;
                    }

                    ref.current.push(conv!);
                
                    setChannels(ref.current);
                }

                if (change.type === "modified")
                {
                    var conv : IChannel | null = Factory.createChannelFromFirebase(change.doc.data(), change.doc.id);

                    if (conv === null)
                    {
                        return;
                    }

                    ref.current = ref.current.filter(item => item.id !== change.doc.id);

                    ref.current.push(conv);

                    setChannels(ref.current);
                }

                if (change.type === "removed")
                {
                    ref.current =  ref.current.filter(item => item.id !== change.doc.id);

                    setChannels(ref.current);
                }
            });
        });

        return () => { unsubscribe(); }

    }, []);

    return { messages, channels }
}

export default useChat;