import './report.css';

import { useContext, useState } from 'react';

import useModal from '../../utils/useModal';
import Button from '../../components/button/button';
import Drop from '../../components/drop/drop';

import { ReactComponent as Close } from '../../assets/icons/close.svg';

import { useAppDispatch } from '../../utils/useStore';

import { setApplicationModal } from '../../core/slice/application';

import { database } from '../../core/services/firebase';

import useForm from '../../utils/useForm';

import { UserContext } from '../../core/providers/user';

import { useToasts } from 'react-toast-notifications';

import data from "../../data/activity.json";

export enum IReportType
{
    CHANNEL,
    AD,
    COMMENT
}

interface IReportProps
{
    document : string
    title : string
    type : IReportType
}

function Report({ document, title, type } : IReportProps)
{
    const [loading, setLoading] = useState<boolean>(false);

    const { node } = useModal();

    const dispatch = useAppDispatch();

    const { inputs, setInput } = useForm({ choice : { value: "Violence", type : "" }, message : "" });

    const { user } = useContext(UserContext);

    const { addToast } = useToasts();

    const handleCloseEvent = function() : void
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleReportAdEvent = async function() : Promise<void>
    {
        setLoading(true);

        try
        {
            await database.collection('reports').add({ sujet : inputs.choice.value, message : inputs.message, owner : user!.id, document : document });

            addToast("Votre signalement a bien été pris en compte.", { appearance: 'success', autoDismiss : true });

            dispatch(setApplicationModal({ index : null, props : null }));
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    const handleReportChannelEvent = async function() : Promise<void>
    {
        setLoading(true);

        try
        {
            await database.collection('channels').doc(document).update({ block : true });
            await database.collection('reports').add({ sujet : inputs.choice.value, message : inputs.message, owner : user!.id, document : document });

            addToast("Votre signalement a bien été pris en compte.", { appearance: 'success', autoDismiss : true });

            dispatch(setApplicationModal({ index : null, props : null }));
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    const handleSubmitEvent = function() : void
    {
        if (inputs.message === "")
        {
            addToast("Veuillez remplir les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        if (type === IReportType.CHANNEL)
        {
            handleReportChannelEvent();
        }
        else
        {
            handleReportAdEvent();
        }
    }

    return (
        <div className="modal-wrapper">
            <div className="report-modal-wrapper" ref={node}>
                <div>
                    <p>{ title }</p>
                    <Close onClick={ () => handleCloseEvent() } />
                </div>
                <div>
                    <Drop title="Sujet" choice={data.report} selected={inputs.choice} onClick={setInput} name="choice" />
                    <div className="profile-help-area">
                        <p>Message</p>
                        <textarea placeholder="Votre message..." name="message" value={inputs.message} onChange={setInput} ></textarea>
                    </div>
                    <Button title={ loading ? "Chargement..." : "Envoyer"} fill={true} onClick={ () => handleSubmitEvent() } />
                    <Button title="Annuler" fill={false} onClick={ () => handleCloseEvent() } />
                </div>
            </div>
        </div>
    );
}

export default Report;