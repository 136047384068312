import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyAPrDGubsZFR5XbZ7I4e4WWsw6IgTN25Mk",
    authDomain: "happybati.firebaseapp.com",
    projectId: "happybati",
    storageBucket: "happybati.appspot.com",
    messagingSenderId: "395104063121",
    appId: "1:395104063121:web:05235100fc2dbdb1c1f930",
    measurementId: "G-6E1BV150J9"
};

var application = null;

if (firebase.apps.length === 0)
{
    application = firebase.initializeApp(firebaseConfig);
}
else
{
    application = firebase.app();
}

function getFirebaseErrorFromCode(code : string) : string
{
    switch (code)
    {
        case "auth/invalid-email" : return ("Adresse e-mail invalide.");
        case "auth/wrong-password" : return ("Mot de passe incorrect.");
        case "auth/user-not-found" : return ("Aucun compte n'est associé à cette adresse e-mail.");
        case "auth/weak-password" : return ("Veuillez saisir un mot de passe de plus de 6 caractères.");
        case "auth/email-already-in-use" : return ("Cette adresse e-mail est déjà associée à un compte.");
        default : return ("undefined");
    }
}

var database = firebase.firestore();
var auth = firebase.auth();
var storage = firebase.storage();

export { application, database, auth, storage, getFirebaseErrorFromCode }