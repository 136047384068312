import { createContext, useState } from "react";
import { IAd } from "../models/model";

export interface ISearch
{
    type : string
    secteur : string
    lieu : string
    rayon : string
    hits : IAd[],
    pages : number
    page : number
}

export const SearchContext = createContext<{ search : ISearch, setSearch : Function }>({ search : { type : "", secteur : "", lieu : "", rayon : "", hits : [], page : 1, pages : 0 }, setSearch : () => {} });

function SearchProvider(props : any)
{
    const [search, setSearch] = useState<ISearch>({ type : "", secteur : "", lieu : "", rayon : "", hits : [], page : 1, pages : 0 });

    return (
        <SearchContext.Provider value={{ search, setSearch }}>
            { props.children }
        </SearchContext.Provider>
    );
}

export default SearchProvider;