import HeaderSettingsView from "./header";

import useAuth from "../../../utils/useAuth";

import { IProfileViewProps } from "./profile";
import { useToasts } from "react-toast-notifications";
import { useAppDispatch } from "../../../utils/useStore";
import { clear } from "../../../core/slice/search";
import { useHistory } from "react-router";

const LogoutProfile = function({ setStep } : IProfileViewProps) : any
{
    const { logout } = useAuth();
    
    const { addToast } = useToasts();

    const dispatch = useAppDispatch();
    
    const handleLogoutEvent = async function() : Promise<void>
    {
        try
        {
            await logout();

            dispatch(clear());
        }
        catch (error)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }
    }
    
    return (
        <div className="profile-menu-view">
           <HeaderSettingsView title="Déconnexion" setStep={setStep} />
           <div className="profile-more-item invisible-scrollbar">
                <p>Déconnexion de votre compte</p>
                <div>
                    <p>Souhaitez-vous vraiment vous déconnecter de votre compte HappyBati ?</p>
                    <button onClick={ () => handleLogoutEvent() }>Déconnexion</button>
                </div>
            </div>
        </div>
    );
}

export default LogoutProfile;