import './profile.css';

import { useState } from 'react';

import MenuItem from '../../../components/menuItem/menuItem';

import { useAppSelector } from '../../../utils/useStore';
import useDevice from '../../../utils/useDevice';

import { selectApplicationProfile } from '../../../core/slice/application';

import MoreProfile from './more';
import BillsProfile from './bills';
import AccountProfile from './account';
import PasswordProfile from './password';
import SettingsProfile from './settings';
import BusinessProfile from './business';
import HelpProfile from './help';
import LogoutProfile from './logout';

export interface IProfileViewProps
{
    setStep : Function
}

const ProfileMenuList =
[
    { name : "Informations personnelles" , to : 1 },
    { name : "Mot de passe" , to : 2 },
    { name : "Mon entreprise" , to : 3 },
    { name : "Moyen de paiement" , to : 4 },
    { name : "Besoin d’aide ?" , to : 5 },
    { name : "Plus" , to : 6 },
    { name : "Déconnexion" , to : 7 }
];

function Profile()
{
    const isDesktop = useDevice();

    const application = useAppSelector(selectApplicationProfile);

    const [step, setStep] = useState<number>(0);

    const dislayCorrectView = function() : any
    {
        switch (application.index)
        {
            case 1 : return <AccountProfile setStep={setStep} />;
            case 2 : return <PasswordProfile setStep={setStep} />;
            case 3 : return <BusinessProfile setStep={setStep} />;
            case 4 : return <SettingsProfile setStep={setStep} />;
            case 5 : return <HelpProfile setStep={setStep} />;
            case 6 : return <MoreProfile setStep={setStep} />;
            case 7 : return <LogoutProfile setStep={setStep} />;
            default : break;
        }
    }

    const dispalyMenu = function() : any
    {
        return (
            <div className="menu">
                <p>Gestion du compte</p>
                { ProfileMenuList.map((item, index) => <MenuItem key={index} item={item} setStep={setStep} />) }
            </div>
        );
    }

    const displayMobile = function() : any
    {
        return (
            <>
                { step === 0 ? dispalyMenu() : dislayCorrectView() }
            </>
        );
    }

    const displayDesktop = function() : any
    {
        return (
            <>
                { dispalyMenu() }
                { dislayCorrectView() }
            </>
        );
    }

    return (
        <div className="profile-view-wrapper">
            <div className="profile-center">
                { isDesktop ? displayDesktop() : displayMobile() }
            </div>
        </div>
    );
}

export default Profile;