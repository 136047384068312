import './drop.css';

import { useEffect, useRef, useState } from 'react';

import { ReactComponent as Arrow } from '../../assets/icons/arrow-down.svg';
import useModal from '../../utils/useModal';

interface IDropProps
{
    title : string
    choice : { value : string, type : string }[]
    selected : { value : string, type : string }
    onClick : Function
    name : string
}

const Drop = function({ title, choice, selected, onClick, name } : IDropProps) : JSX.Element
{
    const [open, setOpen] = useState<boolean>(false);

    var node = useRef<any>();

    useEffect(() => {

        document.addEventListener('mousedown', handleCloseEvent, false);

        return () => { document.removeEventListener('mousedown', handleCloseEvent, false); }

    }, []);

    const handleCloseEvent = function(event : any) : void
    {
        if (node && !node.current.contains(event.target))
        {
            setOpen(false);
        }
    }

    const handleOpenEvent = function() : void
    {
        setOpen(!open);
    }

    const handleSelectedEvent = function(item : { value : string, type : string }) : void
    {
        onClick(name, { value : item.value, type : item.type });
        setOpen(false);
    }

    return (
        <div className="drop-wrapper" ref={node}>
            <p>{ title }</p>
            <button name={name} onClick={ () => handleOpenEvent() }>{ selected.value }<Arrow /></button>
            { open && <div>
                { choice.map((item : { value : string, type : string }) => <p onClick={ () => handleSelectedEvent(item) }>{ item.value }</p>)}
            </div>}
        </div>
    );
}

export default Drop;