import './reset.css';

import { useState } from 'react';

import { useHistory } from 'react-router';

import useAuth from '../../../utils/useAuth';
import useForm from '../../../utils/useForm';

import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import Image from '../../../assets/auth/login.png';

import { useToasts } from 'react-toast-notifications';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';

function Reset()
{
    const [loading, setLoading] = useState<boolean>(false);

    const { reset } = useAuth();

    const { inputs, setInput } = useForm({ email : "" });

    const history = useHistory();

    const { addToast } = useToasts();

    const handleSubmitEvent = async function()
    {
        if (inputs.email === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setLoading(true);

        try
        {
            await reset(inputs.email);
        }
        catch (error : any)
        {
            addToast(error, { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    const handleCloseEvent = function() : void
    {
        history.push('/home/search');
    }

    const handleBackEvent = function()
    {
        history.push('/auth/login');
    }

    return (
        <div className="reset-view-wrapper">
            <div className="auth-image-wrapper">
                <img src={Image} alt="login" />
            </div>
            <div className="auth-right-scroll-wrapper">
                <div className="auth-right-wrapper">
                    <div className="auth-header-wrapper">
                        <p>OUUPS</p>
                        <p>Mot de passe oublié</p>
                        <Close onClick={handleCloseEvent} />
                    </div>
                    <div className="auth-form-wrapper">
                        <p className="reset-title">Comment faire ?</p>
                        <p className="reset-information">Pour récupérer votre mot de passe, entrez simplement votre adresse email de connexion. Nous vous enverrons un nouveau mot de passe sur cette adresse email.</p>
                        <Input name="email" title="Adresse email" value={inputs.email} onChanged={setInput} placeholder="john.doe@gmail.com" />
                        <Button title="Récupérer l’accès à mon compte" fill={true} onClick={handleSubmitEvent} loading={loading} />
                        <Button title="Précédent" fill={false} onClick={handleBackEvent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reset;