import { database } from "./firebase";

import Factory from "./factory";
import { IAd, IBill, IComment } from "../models/model";

class Firestore
{
    // create user to firestore //

    public static async createUserDocumentToFirestore(uid : string, information : any, url : string) : Promise<void>
    {
        try
        {
            await database.collection('users').doc(uid).set({ email : information.email, name : information.name, visibility : true, avatar : url, customerID : "", stripeError : false, billing : 1 });
        }
        catch (error)
        {
            console.log(error);
        }
    }

    // get user from firestore //

    public static async getUserDocumentFromFirestore(uid : string) : Promise<any>
    {
        var document : any;

        try
        {
            document = await database.collection('users').doc(uid).get();
        }
        catch (error)
        {
            console.log(error);
        }

        return (document);
    }

    // get ads from firestore //

    public static async getAdsFromFirestore(limit : number, type : string, callback : Function | null) : Promise<IAd[]>
    {
        var documents : IAd[] = [];

        try
        {
            const snapshots = await database.collection('annonces').where("types", "==", type).where("status", "==", "annonce active").where("delete", "==", false).orderBy('date', 'desc').limit(limit).get();

            if (snapshots.docs.length === 0)
            {
                return (documents);
            }

            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createAdsFromFirebase(document.data(), document.id)!);
            });

            callback && callback(snapshots.docs[snapshots.docs.length - 1]);
        }
        catch (error)
        {
            console.log(error);
        }

        return (documents);
    }

    public static async getUserBillsFromFirestore(uid : string) : Promise<IBill[]>
    {
        var documents : IBill[] = [];

        try
        {
            const snapshots = await database.collection('factures').where("owner", "==", uid).get();

            if (snapshots.docs.length === 0)
            {
                return (documents);
            }

            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createBillsFromFirebase(document.data(), document.id)!);
            });

        }
        catch (error)
        {
            console.log(error);
        }

        return (documents);
    }

    // get ads from firestore //

    public static async getAdsFromFirestoreWithRef(limit : number, type : string, ref : React.MutableRefObject<IAd | undefined>, callback : Function | null) : Promise<IAd[]>
    {
        var documents : IAd[] = [];

        try
        {
            const snapshots = await database.collection('annonces').where("types", "==", type).where("status", "==", "annonce active").where("delete", "==", false).orderBy('date', 'desc').startAfter(ref.current).limit(limit).get();

            if (snapshots.docs.length === 0)
            {
                return (documents);
            }

            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createAdsFromFirebase(document.data(), document.id)!);
            });

            callback && callback(snapshots.docs[snapshots.docs.length - 1]);
        }
        catch (error)
        {
            console.log(error);
        }

        return (documents);
    }

    // get ads by id from firestore //

    public static async getAdsByIdFromFirestore(id : string) : Promise<IAd | null>
    {
        var documents : IAd | null = null;

        try
        {
            const snapshots = await database.collection('annonces').doc(id).get();

            if (snapshots.exists === false)
            {
                return (documents);
            }

            documents = Factory.createAdsFromFirebase(snapshots.data(), snapshots.id);
        }
        catch (error)
        {
            console.log(error);
        }

        return (documents);
    }

    // get current user ads from firestore //

    public static async getUserAdsFromFirestore(uid : string) : Promise<IAd[]>
    {
        var documents : IAd[] = [];

        try
        {
            const snapshots = await database.collection('annonces').where("status", "in", ["annonce active", "annonce désactivée"]).where("delete", "==", false).where("createdBy", "==", uid).get();

            if (snapshots.docs.length === 0)
            {
                return (documents);
            }

            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createAdsFromFirebase(document.data(), document.id)!);
            });
        }
        catch (error)
        {
            console.log(error);
        }

        return (documents);
    }

     // get current finished user ads from firestore //

     public static async getUserFinishedAdsFromFirestore(uid : string) : Promise<IAd[]>
     {
        var documents : IAd[] = [];
 
        try
        {
            const snapshots = await database.collection('annonces').where("status", "in", ["passée"]).where("delete", "==", false).where("createdBy", "==", uid).get();
 
            if (snapshots.docs.length === 0)
            {
                return (documents);
            }
 
            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createAdsFromFirebase(document.data(), document.id)!);
            });
        }
        catch (error)
        {
            console.log(error);
        }
 
        return (documents);
     }

     // get comment //

     public static async getCommentsAdsFromFirestore(uid : string) : Promise<IComment[]>
     {
        var documents : IComment[] = [];
 
        try
        {
            const snapshots = await database.collection('annonces').doc(uid).collection('avis').get();
 
            if (snapshots.docs.length === 0)
            {
                return (documents);
            }
 
            snapshots.docs.forEach(function (document)
            {
                documents.push(Factory.createCommentFromFirebase(document.data(), document.id)!);
            });
        }
        catch (error)
        {
            console.log(error);
        }
 
        return (documents);
     }
}

export default Firestore;