import './priced.css';

import { IAd } from '../../core/models/model';
import { useEffect, useRef } from 'react';

interface IPricedProps
{
    setOpen : Function
    channel : IAd
}

const Priced = function({ setOpen, channel } : IPricedProps) : JSX.Element
{
    var node = useRef<any>();

    const viewsPriced : string = (0.10 * channel.views).toFixed(2).toString();
    const clicksPriced : string = (0.10 * channel.clicks).toFixed(2).toString();
    const messagesPriced : string = (1 * channel.messages).toFixed(2).toString();
    
    const handleCloseEvent = function() : void
    {
        setOpen(false)
    }

    useEffect(function ()
    {
        document.addEventListener('mousedown', handleClose, false);

        return () => { document.removeEventListener('mousedown', handleClose, false) }

    }, []);

    const handleClose = function(event : any)
    {
        if (node && !node.current.contains(event.target))
        {
             setOpen(false);
        }
    }

    return (
        <div className="priced-modal-wrapper" ref={node}>
            <div>
                <p>Infos tarif</p>
                <p onClick={ () => handleCloseEvent() }>Annuler</p>
            </div>
            <div>
                <div>
                    <p>{`${ channel.views } visites`}</p>
                    <p>{ viewsPriced } €</p>
                </div>
                <div>
                    <p>{`${ channel.clicks } cliques`}</p>
                    <p>{ clicksPriced } €</p>
                </div>
                <div>
                    <p>{`${ channel.messages } messages`}</p>
                    <p>{ messagesPriced } €</p>
                </div>
            </div>
        </div>
    );
}

export default Priced;