import './grade.css';

import { ReactComponent as StarEmpty } from '../../assets/home/star-empty.svg';
import { ReactComponent as StarFill } from '../../assets/home/star-fill.svg';

interface IGradeProps
{
    grade : number
}

function Grade({ grade } : IGradeProps)
{
    const starsArray : number[] = [0, 0, 0, 0, 0];

    return (
        <div className="grade">
            { starsArray.map((star, index) => index < grade ? <StarFill key={index} style={{ height : 20, marginRight : 3 }} /> : <StarEmpty key={index} style={{ height : 20, marginRight : 3 }} /> ) }
        </div>
    );
}

export default Grade;