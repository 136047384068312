import './myAds.css';

import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { useEffect, useState } from 'react';

import { useAppSelector } from '../../utils/useStore';

import { fillMyAdsEnableList, selectSearchMyAds, fillMyAdsDisableList, clearTypes } from '../../core/slice/search';

import { database } from '../../core/services/firebase';
import { useToasts } from 'react-toast-notifications';
import { IAd } from '../../core/models/model';
import Priced from '../../modals/priced/priced';

import moment from 'moment';
import firebase from 'firebase';
import { setApplicationModal } from '../../core/slice/application';

interface IMyAdsProps
{
    ad : IAd
}

function MyAds({ ad } : IMyAdsProps)
{
    const statusColor = ad.status === "passée" || ad.status === "annonce désactivée" ? "#DD4848" : "#47D07B";

    const text : string = ad.status === "passée" ? "Renouveler l’annonce" : ad.status === "annonce désactivée" ? "Réactiver" : "Désactiver";

    const history = useHistory();

    const dispatch = useDispatch();

    const application = useAppSelector(selectSearchMyAds);

    const [loading, setLoading] = useState<boolean>(false);

    const { addToast } = useToasts();

    const [priced, setPriced] = useState<boolean>(false);

    const handleSeeAdsEvent = function() : void
    {
        history.push(`/home/search/type/ads/${ad.id}`);

        window.scrollTo({ top : 0, behavior : 'smooth' });
    }

    const handleModifyAdsEvent = function() : void
    {
        history.push(`/home/update/${ ad.id }`);
    }

    const handleDisableStatusAdsEvent = async function() : Promise<void>
    {
        var tmp = [...application.enable!];

        try
        {
            setLoading(true);

            await database.collection('annonces').doc(ad.id).update({ status : 'annonce désactivée' });

            tmp = tmp.filter(annonce => annonce.id !== ad.id);

            const newAd = { ...ad, status : 'annonce désactivée' }

            tmp.push(newAd);

            dispatch(fillMyAdsEnableList(tmp));
            dispatch(clearTypes());

            setLoading(false);

            addToast("Votre annonce est désactivée.", { appearance: 'warning', autoDismiss : true });
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }
    }

    const handleEnableStatusAdsEvent = async function() : Promise<void>
    {
        var tmp = [...application.enable!];

        try
        {
            setLoading(true);

            await database.collection('annonces').doc(ad.id).update({ status : 'annonce active' });

            tmp = tmp.filter(annonce => annonce.id !== ad.id);

            const newAd = { ...ad, status : 'annonce active' }

            tmp.push(newAd);

            dispatch(fillMyAdsEnableList(tmp));
            dispatch(clearTypes());

            setLoading(false);

            addToast("Votre annonce est activée.", { appearance: 'success', autoDismiss : true });
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }
    }

    const handleRenewStatusAdsEvent = async function() : Promise<void>
    {
        
    }

    const handleChangeStatusAdsEvent = function() : void
    {
        switch (ad.status)
        {
            case 'passée': handleRenewStatusAdsEvent(); break;
            case 'annonce désactivée': handleEnableStatusAdsEvent(); break;
            case 'annonce active': handleDisableStatusAdsEvent(); break;
            default: break;
        }
    }

    const handleDeleteAdsEvent = async function() : Promise<void>
    {
        dispatch(setApplicationModal({ index : 7, props : { ad : ad} }));
    }

    const handlePricedEvent = function() : void
    {
        setPriced(!priced);
    }

    const getCurrentPrice = function() : string
    {
        const viewsPriced : number = (0.10 * ad.views);
        const clicksPriced : number = (0.10 * ad.clicks);
        const messagesPriced : number = (1 * ad.messages);

        return (viewsPriced + clicksPriced + messagesPriced).toFixed(2).toString();
    }

    const getEndDate = function() : string
    {
        const startDate = moment(firebase.firestore.Timestamp.now().toDate().getTime());
        const timeEnd = moment(ad.dateEnd.toDate().getTime());

        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);

        if (diffDuration.hours() <= 0 && diffDuration.days() <= 0)
        {
            return ("Passée");
        }

        if (diffDuration.days() !== 0)
        {
            return (`Fin dans ${ diffDuration.days() }j et ${ diffDuration.hours() }h`);
        }

        return (`Fin dans ${ diffDuration.hours() }h`);
    }

    return (
        <div className="my-ads-wrapper">
            <img src={ ad.image } alt="ad show" />
            <div className="my-ads-information-wrapper">
                <div className="my-ads-type-status">
                    <p>{ getEndDate() }</p>
                </div>
                <div className="my-ads-text-information">
                    <p>{ ad.title }</p>
                    <p>{ ad.description }</p>
                </div>
                <div className="my-ads-info-wrapper">
                    <p>Secteur : { ad.secteur }</p>
                    <span />
                    <p>Statut : <span style={{ color : statusColor }}>{ ad.status }</span></p>
                    <span />
                    <p>{`Coût actuel de votre annonce : ${ getCurrentPrice() } €`}</p>
                    <p onClick={ () => handlePricedEvent() }>Voir les infos tarif</p>
                    { priced && <Priced setOpen={setPriced} channel={ad} /> }
                </div>
                <div className="my-ads-buttons-wrapper">
                    <button onClick={ () => handleSeeAdsEvent() } disabled={loading}>
                        Voir l'annonce
                    </button>
                    <div />
                    <button onClick={ () => handleModifyAdsEvent() } disabled={loading}>
                        Modifier
                    </button>
                    <div />
                    <button onClick={ () => handleChangeStatusAdsEvent() } disabled={loading}>
                        { text }
                    </button>
                    <div />
                    <button onClick={ () => handleDeleteAdsEvent() } disabled={loading}>
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MyAds;