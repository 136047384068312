import { useEffect, useState } from "react";

function useDevice()
{
    const [isDesktop, setIsDeskTop] = useState<boolean>(true);

    useEffect(() => {
        
        window.innerWidth <= 1300 ? setIsDeskTop(false) : setIsDeskTop(true);

        window.addEventListener('resize', handleResizeEvent);

        return () => { window.removeEventListener('resize', handleResizeEvent); };

    }, []);

    const handleResizeEvent = function() : void
    {
        window.innerWidth <= 1300 ? setIsDeskTop(false) : setIsDeskTop(true);

        console.log(window.innerWidth);
    }

    return (isDesktop);
}

export default useDevice;