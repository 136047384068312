import './dc.css';

import { useState } from "react";

import Button from "../../components/button/button";

import { setApplicationModal } from "../../core/slice/application";

import useModal from "../../utils/useModal";

import { useAppDispatch, useAppSelector } from "../../utils/useStore";

import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { database } from "../../core/services/firebase";
import { clearTypes, fillMyAdsDisableList, fillMyAdsEnableList, selectSearchMyAds } from "../../core/slice/search";
import { useToasts } from "react-toast-notifications";
import { IAd } from "../../core/models/model";

const DeleteAd = function({ ad } : { ad : IAd}) : JSX.Element
{   
    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<string>('');

    const { node } = useModal();

    const dispatch = useAppDispatch();

    const { addToast } = useToasts();
    
    const application = useAppSelector(selectSearchMyAds);
    
    const handleDeleteEvent = async function() : Promise<void>
    {
        setLoading(true);

        try
        {
            var tmp = ad.status === "passée" ? [...application.disable!] : [...application.enable!];

            try
            {
                setLoading(true);
    
                await database.collection('annonces').doc(ad.id).update({ delete : true });
    
                tmp = tmp.filter(annonce => annonce.id !== ad.id);
    
                dispatch(ad.status === "passée" ? fillMyAdsDisableList(tmp) : fillMyAdsEnableList(tmp));
                dispatch(clearTypes());
                
                setLoading(false);
    
                addToast("Votre annonce a bien été supprimé.", { appearance: 'success', autoDismiss : true });
            }
            catch (error : any)
            {
                addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
            }
        }
        catch (error : any)
        {
            setError("Une erreur indéfinie s'est produite.");
        }

        setLoading(false);

        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleCloseEvent = function() : void
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleCancelEvent = async function() : Promise<void>
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    return (
        <div className="modal-wrapper">
            <div className="delete-custom-modal-wrapper" ref={node}>
                <div>
                    <h2>Supprimer cette annonce ?</h2>
                    <Close onClick={ () => handleCloseEvent() } />
                </div>
                <p>Êtes-vous certain(e) de vouloir supprimer cette annonce ?</p>
                <div>
                    <Button title="Annuler" fill={false} onClick={handleCancelEvent} loading={loading} />
                    <Button title={ loading ? "Chargement..." : "supprimer" } fill={true} onClick={handleDeleteEvent} loading={loading} />
                </div>
            </div>
        </div>
    );
}

export default DeleteAd;