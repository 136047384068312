import './loading.css';

import { GridSpinner } from "react-spinners-kit";

const Loading = function() : JSX.Element
{
    return (
        <div className="loading-modal">
            <GridSpinner size={100} />
        </div>
    );
}

export default Loading;