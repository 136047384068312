import './monitor.css';

import useModal from "../../utils/useModal";

import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { setApplicationModal } from '../../core/slice/application';
import { useAppDispatch } from '../../utils/useStore';

interface MonitorModalProps
{
    url : string
}

const Monitor = function({ url } : MonitorModalProps) : JSX.Element
{   
    const { node } = useModal();

    const dispatch = useAppDispatch();

    const handleCloseEvent = function() : void
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }
    
    return (
        <div className="modal-wrapper">
            <div className="monitor-modal-wrapper" ref={node}>
                <div>
                    <Close onClick={ () => handleCloseEvent() } />
                </div>
               <img src={url} />
            </div>
        </div>
    );
}

export default Monitor;