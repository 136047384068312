import './index.css';

import ReactDOM from 'react-dom';

import App from './App';

import SearchProvider from './core/providers/search';
import UserProvider from './core/providers/user';

import { Provider } from 'react-redux';

import { store } from './core/services/store';

import { ToastProvider } from 'react-toast-notifications';

ReactDOM.render(
    <Provider store={store}>
        <ToastProvider placement="bottom-right">
            <UserProvider>
                <SearchProvider>
                    <App />
                </SearchProvider>
            </UserProvider>
        </ToastProvider>
    </Provider>,
    document.getElementById('root')
);