import './realisation.css';

import { IAd, IUser } from "../../core/models/model";

interface IRealisationProps
{
    ad : IAd
    parent : IUser
}

const Realisation = function({ ad, parent } : IRealisationProps) : JSX.Element
{
    if (ad.makes === undefined || ad.makes.length <= 0)
    {
        return (<></>);
    }

    return (
        <div className="realisation-wrapper">
            <p>{ `Les réalisations de ${parent.name}`  }</p>
            <div>
                { ad.makes.map(image => <div><img src={image} alt="make" /></div> ) }
            </div>
        </div>
    );
}

export default Realisation;