import { database } from "./firebase";

class Application
{
    static async getNumberOfPage(type : string) : Promise<any>
    {
        var document : any = null;

        try
        {
            const snapshots = await database.collection('application').get();

            if (snapshots.docs.length === 0)
            {
                return (document);
            }

            document = type === "humains" ? snapshots.docs[0].data()['humains'] : snapshots.docs[0].data()['matériels'];
        }
        catch (error : any)
        {
            console.log(error);
        }

        return (document);
    }
}

export default Application;