import './messages.css';

import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import useChat from '../../../utils/useChat';
import useObserver from '../../../utils/useObserver';

import Message from '../../../components/message/message';
import Channel from '../../../components/channel/channel';
import ChatInput from '../../../components/chatInput/chatInput';
import ChatInfo from '../../../components/chatInfo/chatInfo';
import ChatHeader from '../../../components/chatHeader/chatHeader';

import { UserContext } from '../../../core/providers/user';
import { IChannel } from '../../../core/models/model';

import { useAppDispatch } from '../../../utils/useStore';
import { setApplicationModal } from '../../../core/slice/application';

export enum IChatStepView
{
    CHANNELS,
    MESSAGE,
    INFORMATION
}

const Messages = function() : JSX.Element
{
    const [step, setStep] = useState<IChatStepView>(IChatStepView.CHANNELS);

    const [isDesktop, setIsDesktop] = useState<boolean>(false);

    const [selected, setSelected] = useState<IChannel | null>(null);

    const { messages, channels } = useChat(selected);
    
    const observer = useObserver();

    const { user } = useContext(UserContext);

    const dispatch = useAppDispatch();
    
    useEffect(() => {

        window.innerWidth <= 800 ? setIsDesktop(false) : setIsDesktop(true);

        window.addEventListener('resize', handleDeviceEvent);

        return () => { window.removeEventListener('resize', handleDeviceEvent); }

    }, []);

    useEffect(() => {

        if (channels.length <= 0)
        {
            return;
        }

        if (selected !== null && selected.block === false)
        {
            return;
        }

        const index = channels.findIndex(item => item.block === false);

        if (index === -1)
        {
            setSelected(channels[0]);
        }
        else
        {
            setSelected(channels[index]);
        }

    }, [channels]);

    const handleDeviceEvent = function() : void
    {
        window.innerWidth <= 800 ? setIsDesktop(false) : setIsDesktop(true);
    }

    const handleStepEvent = function(where : IChatStepView)
    {
        setStep(where);
    }

    const handleChannelClick = function(channel : IChannel) : void
    {
        if (selected && channel.id === selected.id && step !== IChatStepView.CHANNELS)
        {
            return;
        }

        setSelected(channel);
        setStep(IChatStepView.MESSAGE);
    }

    const mobile = function() : JSX.Element
    {
        if (channels.length === 0)
        {
            return (<p className='no-message-warning'>Vous n'avez pas de message pour le moment.</p>);
        }

        return (
            <>
                { step === IChatStepView.CHANNELS && <div className="message-channel-list">
                    <p>Messages</p>
                    { channels.map((channel, index) => !channel.block && <Channel key={index} setSelected={handleChannelClick} channel={channel} selected={selected} /> ) }
                    <p>Bloqués</p>
                    { channels.map((channel, index) => channel.block && <Channel key={index} setSelected={handleChannelClick} channel={channel} selected={selected} /> ) }
                </div> }
                { step === IChatStepView.MESSAGE && <div className="message-chat">
                    <ChatHeader goBack={handleStepEvent} channel={selected} setSelected={setSelected} />
                    <section>
                        { messages.map((message, index) => <Message callbackOpenMonitor={openModalMonitor} key={index} observer={observer} message={message} user={user} channel={selected!} />) }
                    </section>
                    <ChatInput channel={selected} />
                </div> }
                { step === IChatStepView.INFORMATION && <ChatInfo channel={selected} goBack={handleStepEvent} /> }
            </>
        );
    }

    const openModalMonitor = function(url : string) : void
    {
        dispatch(setApplicationModal({ index: 8, props: { url : url } }));
    }

    const desktop = function() : JSX.Element
    {
        if (channels.length === 0)
        {
            return (<p className='no-message-warning'>Vous n'avez pas de message pour le moment.</p>);
        }

        return (
            <>
                <div className="message-channel-list">
                    <p>Messages</p>
                    { channels.map((channel, index) => !channel.block && <Channel key={index} setSelected={handleChannelClick} channel={channel} selected={selected} /> ) }
                    <p>Bloqués</p>
                    { channels.map((channel, index) => channel.block && <Channel key={index} setSelected={handleChannelClick} channel={channel} selected={selected} /> ) }
                </div>
                <div className="message-chat">
                    <ChatHeader goBack={handleStepEvent} channel={selected} setSelected={setSelected} />
                    <section>
                        { messages.map((message, index) => <Message callbackOpenMonitor={openModalMonitor} key={index} observer={observer} message={message} user={user} channel={selected!} />) }
                    </section>
                    <ChatInput channel={selected} />
                </div>
                <ChatInfo channel={selected} goBack={handleStepEvent} />
            </>
        );
    }

    return (
        <div className="messages-view-wrapper invisible-scrollbar">
            <div className="messages-section">
                { isDesktop ? desktop() : mobile() }
            </div>
        </div>
    );
}

export default Messages;