import './input.css';

import { useState } from 'react';

interface IInputProps
{
    title : string
    placeholder ? : string
    value  : string
    onChanged : any
    type ? : string
    name : string
    icon ? : any
}

function Input({ title, placeholder, value, onChanged, type, name, icon } : IInputProps)
{
    const [visible, setVisible] = useState<string>(type ? type : '');

    const handleclickEvent = function() : void
    {
        if (type === 'password')
        {
            if (visible === 'password')
            {
                setVisible('text');
            }
            else
            {
                setVisible('password');
            }
        }
    }

    return (
        <div className="input-wrapper">
            <p>{ title }</p>
            <div>
                <input name={name} value={value} type={visible} placeholder={placeholder} onChange={onChanged} autoComplete="off" />
                <div className={(visible === 'password' || type === "text") ? 'hide-password-input' : 'show-password-input'} onClick={ () => handleclickEvent() }>
                    { icon && icon }
                </div>
            </div>
        </div>
    );
}

export default Input;