import { Route, Switch } from 'react-router';

import Footer from '../../../components/footer/footre';
import Ads from './ads';
import Start from './start';
import CustomSearcView from './custom';

function Search()
{
    return (
        <>
            <Switch>
                <Route exact path="/home/search/custom" component={CustomSearcView} />
                <Route path="/home/search/:type" component={Ads} />
                <Route exact path="/home/search" component={Start} />
            </Switch>
            <Footer />
        </>
    );
}

export default Search;