import { useState } from "react";

function useForm(initial : any)
{
    // state //

    const [inputs, set] = useState<any>(initial);

    // change current object value //

    const setInput = function(event : any)
    {
        set({ ...inputs, [event.target.name] : event.target.value });
    }

    const handleChangeByOne = function(who : string, value : any) : void
    {
        set({ ...inputs, [who] : value });
    }

    // verify current object value //

    const verify = function() : boolean
    {
        return (true);
    }
    
    return { inputs, setInput, verify, handleChangeByOne, set };
}

export default useForm;