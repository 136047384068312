import './start.css';

import Types from '../../../components/Types/types';
import Search from '../../../components/search/search';

import How from '../../../assets/home/home-how.png';
import Mobile from '../../../assets/home/home-how-mobile.png';

import { useContext } from 'react';
import { UserContext } from '../../../core/providers/user';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../../utils/useStore';
import { useToasts } from 'react-toast-notifications';

import { setApplicationMenuIndex } from '../../../core/slice/application';

function Start()
{
    const { user } = useContext(UserContext);

    const history = useHistory();

    const dispatch = useAppDispatch();

    const { addToast } = useToasts();

    const handleAddEvent = function() : void
    {
        if (user === null)
        {
            history.push('/auth/login');

            return;
        }

        if (user!.companie === undefined)
        {
            dispatch(setApplicationMenuIndex(3));

            addToast("Vous devez enregister une entreprise pour pouvoir poster une annonce.", { appearance: 'info', autoDismiss : true, autoDismissTimeout : 6000 });

            history.push('/home/profile');
        }
        else
        {
            history.push('/home/create');
        }
    }

    const handleLoginEventClick = function() : void
    {
        history.push('/auth/login');
    }

    return (
        <div className="start-view-wrapper">
            <Search />
            <p className="expore">EXPLORER</p>
            <p className="question">De quoi avez-vous besoin ?</p>
            <Types title="Moyens matériels" type="matériels" />
            <Types title="Moyens humains" type="humains" />
            <section className="how-section">
                <img className="how-image" src={How} alt="how" />
                <img className="how-image-mobile" src={Mobile} alt="how mobile" />
            </section>
            <section className="pro-section">
                <p>Vous êtes un professionnel ?</p>
                <p>HappyBati est le site dédié aux Entreprises, Pme, Autoentrepreneurs et Fournisseurs qui veulent trouver ou proposer leurs besoins humains ou matériels.</p>
                <button onClick={ () => handleAddEvent() }>Poster une annonce</button>
            </section>
        </div>
    );
}

export default Start;