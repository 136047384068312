import { Redirect, Route, Switch } from "react-router-dom";

import { useContext } from "react";

import { UserContext } from "../core/providers/user";

import Search from "../views/home/search/search";
import Messages from "../views/home/messages/messages";
import Profile from "../views/home/profile/profile";
import MyAds from "../views/home/ads/ads";
import Create from "../views/home/create/create";

import Guard from "./guard";

import Header from "../components/header/header";

import CguView from "../views/infos/cgu";
import CgvView from "../views/infos/cgv";
import MentionView from "../views/infos/mention";
import PolitiqueView from "../views/infos/politique";

function HomeRoute()
{
    const { user } = useContext(UserContext);

    return (
        <>
            <Header />
            <Switch>
                <Guard path="/home/messages" component={<Messages />} logged={user !== null} />
                <Guard path="/home/profile" component={<Profile />} logged={user !== null} />
                <Guard path="/home/ads" component={<MyAds />} logged={user !== null} />
                <Guard path="/home/create" component={<Create />} logged={user !== null} />
                <Guard path="/home/update/:id" component={<Create />} logged={user !== null} />
                <Route exact path="/cgu" component={CguView} />
                <Route exact path="/cgv" component={CgvView} />
                <Route exact path="/mentions" component={MentionView} />
                <Route exact path="/politique" component={PolitiqueView} />
                <Route path="/home/search" component={Search} />
                <Redirect exact from="/" to="/home/search" />
            </Switch>
        </>
    );
}

export default HomeRoute;