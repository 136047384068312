import { useContext, useState } from "react";

import Button from "../../../components/button/button";
import Input from "../../../components/input/input";

import HeaderSettingsView from "./header";

import useForm from "../../../utils/useForm";

import { IProfileViewProps } from "./profile";

import { UserContext } from "../../../core/providers/user";
import { database } from "../../../core/services/firebase";
import { useToasts } from "react-toast-notifications";

import Drop from "../../../components/drop/drop";

import Data from '../../../data/activity.json';

const BusinessProfile = function({ setStep } : IProfileViewProps) : any
{
    const { user, setUser } = useContext(UserContext);

    const companie = user!.companie;

    const { inputs, setInput, handleChangeByOne } = useForm({ siren : companie ? companie.siren : "", phone : companie ? companie.phone : "", name : companie ? companie.name : "", choice : companie ? { value : companie.size, type : "" } : { value : "Entre 0 et 10 salariés", type : "" } });

    const [loading, setLoading] = useState<boolean>(false);

    const { addToast } = useToasts();

    const handleSaveInformation = async function() : Promise<void>
    {
        if (inputs.siren === "" || inputs.phone === "", inputs.name === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setLoading(true);

        try
        {
            await database.collection('users').doc(user!.id).update({ companie : { siren : inputs.siren, phone : inputs.phone, name : inputs.name, size : inputs.choice.value } });

            user!.companie = { siren : inputs.siren, phone : inputs.phone, name : inputs.name, size : inputs.choice.value };

            setUser(user);

            addToast('Vos informations ont bien été mis à jour.', { appearance: 'success', autoDismiss : true });
        }
        catch (error)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    return (
        <div className="profile-menu-view">
            <HeaderSettingsView title="Mon entreprise" setStep={setStep} />
            <div className="profile-business invisible-scrollbar">
                <Input title="Nom de L'entreprise" value={inputs.name} onChanged={setInput} name="name" />
                <Input title="Numéro SIREN" value={inputs.siren} onChanged={setInput} name="siren" />
                <Drop title="Nombre de salariés" choice={Data.companieSize} selected={inputs.choice} onClick={handleChangeByOne} name="choice" />
                <Input title="Numéro de téléphone" value={inputs.phone} onChanged={setInput} name="phone" type="number" />
                <Button title={ loading ? "Chargement..." : "Sauvegarder" } fill={true} onClick={ () => handleSaveInformation() } />
            </div>
        </div>
    );
}

export default BusinessProfile;