import './channel.css';

import Avatar from '../avatar/avatar';

import { IChannel, IUser } from '../../core/models/model';
import { useContext, useEffect, useState } from 'react';
import Firestore from '../../core/services/firestore';
import Factory from '../../core/services/factory';
import { UserContext } from '../../core/providers/user';

interface IChannelProps
{
    setSelected : Function
    channel : IChannel
    selected : IChannel | null
}

const Channel = function({ setSelected, channel, selected } : IChannelProps) : JSX.Element
{
    const isSelected = selected && selected.id === channel.id ? true : false;

    const [to, setTo] = useState<IUser | null>(null);

    const { user } = useContext(UserContext);

    useEffect(() =>
    {
        if (channel === null)
        {
            return;
        }

        const getToUser = async function() : Promise<void>
        {
            var other = null;

            if (channel!.parent === user!.id)
            {
                const uid = channel.users.filter((u) => u !== user!.id);

                other = await Firestore.getUserDocumentFromFirestore(uid[0]);
            }
            else
            {
                other = await Firestore.getUserDocumentFromFirestore(channel!.parent);
            }

            setTo(Factory.createUserFromFirebase(other.data()));
        }

        getToUser();

    }, [channel]);

    return (
        <div className={`channel-item ${ isSelected && "channel-item-active" }`} onClick={ () => setSelected(channel) }>
            { to && <Avatar src={to.avatar} /> }
            { to && <div className="channel-text">
                <p>{ to.name }</p>
                <p>{ channel.last && channel.last }</p>
            </div>}
            { to === null && <p>Chargement...</p> }
        </div>
    );
}

export default Channel;