import './profile.css';

import { useEffect, useRef } from 'react';

import useAuth from '../../utils/useAuth';

import { useAppDispatch } from '../../utils/useStore';

import { setApplicationMenuIndex } from '../../core/slice/application';

import { useHistory } from 'react-router-dom';
import { clear } from '../../core/slice/search';

function Profile({ setProfileOpen } : any)
{
    const { logout } = useAuth();

    var node = useRef<any>();

    const dispatch = useAppDispatch();

    const history = useHistory();

    useEffect(function ()
    {
        document.addEventListener('mousedown', handleClose, false);

        return () => { document.removeEventListener('mousedown', handleClose, false) }

    }, []);

    const handleClose = function(event : any)
    {
        if (node && !node.current.contains(event.target))
        {
             setProfileOpen(false);
        }
    }

    const handleGoToEvent = function(where : number) : void
    {
        setProfileOpen(false);

        dispatch(setApplicationMenuIndex(where));

        history.push('/home/profile');
    }
    
    const handleLogoutEvent = async function() : Promise<void>
    {
        setProfileOpen(false);
        
        await logout();

        dispatch(clear());

        window.scrollTo({ top : 0, behavior : 'smooth' });
    }

    return (
        <div className="profile-modal-wrapper" ref={node}>
            <div>
                <p>Mon profil</p>
            </div>
            <p onClick={ () => handleGoToEvent(1) }>Gestion du compte</p>
            <p onClick={ () => handleLogoutEvent() }>Déconnexion</p>
        </div>
    );
}

export default Profile;