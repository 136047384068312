import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AuthRoute from "./routes/auth";
import HomeRoute from "./routes/home";

import ModalManager from "./modals/manager/modalManager";

import { useContext, useEffect } from "react";

import { UserContext } from "./core/providers/user";

import { useAppDispatch } from "./utils/useStore";

import { setApplicationModal } from "./core/slice/application";

function App()
{
    const { user } = useContext(UserContext);

    const dispatch = useAppDispatch();

    useEffect(() => {

        if (user && user.stripeError === true)
        {
            dispatch(setApplicationModal({ index : 5, props : null }));
        }
        
    }, [user]);
    
    return (
        <div className="app-wrapper">
            <Router>
                <Switch>
                    <Route path="/auth" component={AuthRoute} />
                    <Route path="/" component={HomeRoute} />
                </Switch>
                <ModalManager />
            </Router>
        </div>
    );
}

export default App;