import './avatar.css';

const Avatar = function({ src } : any) : JSX.Element
{
    return (
        <div className="avatar">
            <img src={src} alt="profile" />
        </div>
    );
}

export default Avatar;