import { storage } from "./firebase";

class Storage
{
    // add file to firebase storage //

    public static async addToStorage(path : string, file : string) : Promise<any>
    {
        var url : Promise<any> | undefined;

        try
        {
            await storage.ref().child(path).putString(file, 'data_url');

            url = await storage.ref().child(path).getDownloadURL();
        }
        catch (error : any)
        {
            throw new Error(error); 
        }

        return (url);
    }

    // remove from firebase storage //

    public static async removeFromStorage(name : string) : Promise<void>
    {
        try
        {
            await storage.ref().child(name).delete();
        }
        catch (error : any)
        {
            throw new Error(error); 
        }
    }
}

export default Storage;