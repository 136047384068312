import './create.css';

import { useContext, useEffect, useState } from 'react';

import Button from '../../../components/button/button';
import Input from '../../../components/input/input';
import Drop from '../../../components/drop/drop';

import { useHistory, useParams } from 'react-router-dom';

import useForm from '../../../utils/useForm';

import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Euro } from '../../../assets/icons/euro.svg';

import { useAppDispatch, useAppSelector } from '../../../utils/useStore';
import { setApplicationModal } from '../../../core/slice/application';

import { database } from '../../../core/services/firebase';

import Data from '../../../data/activity.json';
import { fillMyAdsDisableList, fillMyAdsEnableList, selectSearchMyAds } from '../../../core/slice/search';
import Factory from '../../../core/services/factory';
import { UserContext } from '../../../core/providers/user';
import { useToasts } from 'react-toast-notifications';
import Storage from '../../../core/services/storage';
import firebase from 'firebase';
import PrettoSlider from '../../../components/slider/slider';

import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import AdresseListed from '../../../components/adresselisted/adresselisted';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';

import Firestore from '../../../core/services/firestore';

const Create = function() : JSX.Element
{
    const { id } = useParams<{ id : string }>();
    
    const [step, setStep] = useState<number>(1);

    const [annonceID, setAnnonceID] = useState<string>("");

    const [open, setOpen] = useState<boolean>(false);

    const [coord, setPosition] = useState<{ lat : number, lng : number, adress : any } | null>(null);

    const { inputs, setInput, handleChangeByOne, set } = useForm({ 
        title : "",
        secteur : { "value" : "Peinture", type : "matériels" },
        price : "",
        hide : { "value" : "Oui", type : "" },
        rayon : { value : "< 20Km", type : "" }
    });

    const [desciption, setDescription] = useState<string>("");

    const history = useHistory();

    const dispatch = useAppDispatch();

    const application = useAppSelector(selectSearchMyAds);

    const { user } = useContext(UserContext);

    const [fileAd, setFileAd] = useState<any>(null);
    const [fileMake, setFileMake] = useState<any[]>([]);

    const { addToast } = useToasts();

    const [time, setTime] = useState<number>(10);

    const { value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({ debounce: 300 });

    useEffect(() => {

        if (id === null || id === "" || id === undefined)
        {
            return;
        }

        const fetchAd = async function() : Promise<void>
        {
            dispatch(setApplicationModal({ index : 1, props : null }));

            const adToUpdate = await Firestore.getAdsByIdFromFirestore(id);

            if (adToUpdate === null)
            {
                addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });

                return;
            }

            const tmp = {
                title : adToUpdate.title,
                secteur : { "value" : adToUpdate.secteur, type : adToUpdate.types },
                price : adToUpdate.price,
                hide : { "value" : adToUpdate.hidePhoneNumber ? "Oui" : "Non", type : "" },
                rayon : adToUpdate.rayon
            }

            set(tmp);

            setPosition({ lat : adToUpdate._geoloc.lat, lng : adToUpdate._geoloc.lng, adress : adToUpdate.adress });

            setAnnonceID(id);
            setDescription(adToUpdate.description);
            setFileAd(adToUpdate.image);
            setFileMake(adToUpdate.makes);

            setValue(adToUpdate.adress);

            dispatch(setApplicationModal({ index : null, props : null }));
        }

        fetchAd();

    }, []);

    const handlePhotoEvent = function() : void
    {
        if (inputs.title === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setStep(step + 1);
    }

    const handleMakeEvent = function() : void
    {
        if (fileAd === null)
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setStep(step + 1);
    }

    const handleMoreInformationEvent = function() : void
    {
        setStep(step + 1);
    }

    const handlePayEvent = function() : void
    {
        if (inputs.price === "" || coord === null)
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        if (id !== undefined)
        {
            setStep(step + 2);

            return;
        }

        setStep(step + 1);
    }

    const goToNextStep = function() : void
    {
        setStep(step + 1);
    }

    const goToPrevStep = function() : void
    {
        setStep(step - 1);
    }

    const handleModifyEvent = function() : void
    {
        setStep(1);
    }

    const handleSeeMyAdsEvent = function() : void
    {
        history.push(`/home/search/:type/ads/${annonceID}`);
    }

    const handleGoToHomeEvent = function() : void
    {
        history.goBack();
    }

    function isValidHttpUrl(str : string) {
        let url;
        
        try {
          url = new URL(str);
        } catch (_) {
          return false;
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
      }

    const handleUpdate = async function() : Promise<void>
    {
        try
        {
            dispatch(setApplicationModal({ index : 1, props : null }));

            var url = null;

            if (isValidHttpUrl(fileAd) === false)
            {
                url = await Storage.addToStorage(`/ads/${id}/`, fileAd);
            }

            var list : string[] = [];

            if (fileMake.length > 0)
            {
                for (var position = 0; position < fileMake.length; position++)
                {
                    if (isValidHttpUrl(fileMake[position]) === false)
                    {
                        const urlmake = await Storage.addToStorage(`/ads/${id + position}/`, fileMake[position]);

                        list.push(urlmake);
                    }
                }
            }

            const annonce = {
                types : inputs.secteur.type,
                secteur : inputs.secteur.value,
                title : inputs.title,
                delete : false,
                adress : coord!.adress,
                price : inputs.price,
                description : desciption,
                rayon : inputs.rayon,
                image : url !== null ? url : fileAd,
                makes : list.length >= 0 ? list : fileMake,
                hidePhoneNumber : inputs.hide.value === "Nom" ? false : true,
                "_geoloc": {
                    "lat": coord!.lat,
                    "lng": coord!.lng
                }
            };

            await database.collection('annonces').doc(id).update({ ...annonce });

            setStep(step + 1);

            dispatch(setApplicationModal({ index : null, props : null }));

            dispatch(fillMyAdsEnableList(null));
            dispatch(fillMyAdsDisableList(null));
        }
        catch (error)
        {
            addToast("error", { appearance: 'error', autoDismiss : true });
        }
    }

    const handleFinishEvent = async function() : Promise<void>
    {
        var tmp = [...application.enable!];

        try
        {
            dispatch(setApplicationModal({ index : 1, props : null }));

            var date = new Date();

            date.setDate(date.getDate() + time);

            const annonce = {
                date : firebase.firestore.Timestamp.fromDate(new Date()),
                createdBy : user!.id,
                types : inputs.secteur.type,
                secteur : inputs.secteur.value,
                title : inputs.title,
                adress : coord!.adress,
                grade : 0,
                status : "annonce active",
                price : inputs.price,
                description : desciption,
                rayon : inputs.rayon,
                delete : false,
                image : "",
                makes : [""],
                avis : 0,
                dateEnd : firebase.firestore.Timestamp.fromDate(date),
                hidePhoneNumber : inputs.hide.value === "Nom" ? false : true,
                views : 0,
                clicks : 0,
                messages : 0,
                "_geoloc": {
                    "lat": coord!.lat,
                    "lng": coord!.lng
                }
            };

            const document = await database.collection('annonces').add(annonce);

            setAnnonceID(document.id);

            const url = await Storage.addToStorage(`/ads/${document.id}/`, fileAd);

            var list : string[] = [];

            if (fileMake.length > 0)
            {
                for (var position = 0; position < fileMake.length; position++)
                {
                    const urlmake = await Storage.addToStorage(`/ads/${document.id + position}/`, fileMake[position]);

                    list.push(urlmake);
                }
            }

            await database.collection('annonces').doc(document.id).update({ image : url, makes : list });

            annonce.image = url;
            annonce.makes = list;

            setStep(step + 1);

            dispatch(setApplicationModal({ index : null, props : null }));

            tmp.push(Factory.createAdsFromFirebase(annonce, document.id)!);

            dispatch(fillMyAdsEnableList(tmp));
        }
        catch (error : any)
        {
            console.log(error);
        }
    }

    const handleSelectFileAdEvent = function(event : any) : void
    {
        event.preventDefault();

        if (event.target.files !== null && event.target.files.length > 0)
        {
            let reader = new FileReader();
        
            reader.readAsDataURL(event.target.files[0]);

            reader.onload = () => {
                setFileAd(reader.result);
            };
        }
    }

    const handleSelectFileMakeAdEvent = function(event : any) : void
    {
        if (fileMake.length >= 5)
        {
            return;
        }

        event.preventDefault();

        if (event.target.files !== null && event.target.files.length > 0)
        {
            let reader = new FileReader();
        
            reader.readAsDataURL(event.target.files[0]);

            reader.onload = () => {
                setFileMake([...fileMake, reader.result]);
            };
        }
    }

    const step_1 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 1</p>
                <p>Informations</p>
                <div className="create-form step-1-form">
                    <Input title="Titre de l’annonce" placeholder="Peinture sur Paris" value={inputs.title} onChanged={setInput} type="text" name="title" />
                    <p>Description de l’annonce</p>
                    <textarea id="story" name="story" value={desciption} onChange={ (event) => setDescription(event.target.value) } placeholder="Votre description.." />
                    <Drop title="Secteur d’activité" choice={Data.secteur} selected={inputs.secteur} onClick={handleChangeByOne} name="secteur" />
                    <Button title="Suivant" fill={true} onClick={handlePhotoEvent} />
                    <Button title="Annuler" fill={false} onClick={handleGoToHomeEvent} />
                </div>
            </div>
        );
    }

    const step_2 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 2</p>
                <p>Photo d’annonce</p>
                <div className="create-form step-2-form">
                    <p>Photo d’annonce</p>
                    <label htmlFor="fileInputCreate">
                        { fileAd && <img src={fileAd} alt="ad" /> }
                        <div>
                            <Upload />
                            { fileAd === null && <p>Cliquez ici pour choisir une photo</p> }
                        </div>
                    </label>
                    <input type="file" id="fileInputCreate" onChange={handleSelectFileAdEvent} accept="image/jpg, image/png, image/jpeg" />
                    <Button title="Suivant" fill={true} onClick={handleMakeEvent} />
                    <Button title="Précédent" fill={false} onClick={goToPrevStep} />
                </div>
            </div>
        );
    }

    const removeImageAt = function(index : number) : void
    {
        var tmp = [...fileMake];

        tmp.splice(index, 1);
        
        setFileMake(tmp);
    }

    const step_3 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 3</p>
                <p>Ajouter vos réalisations</p>
                <div className="create-form step-3-form">
                    <p>`Photos de vos réalisations ({ fileMake.length}/5)`</p>
                    <div>
                        <label htmlFor="fileInputCreateMake">
                            <Upload />
                            <p>Cliquez ici pour choisir une photo</p>
                        </label>
                        { fileMake.map((image, index) => <div key={index} className="make-img"><img src={image} alt="make" /><Close onClick={ () => removeImageAt(index) }/></div> ) }
                    </div>
                    <input type="file" id="fileInputCreateMake" onChange={handleSelectFileMakeAdEvent} accept="image/jpg, image/png, image/jpeg" disabled={fileMake.length >= 5}/>
                    <Button title="Suivant" fill={true} onClick={handleMoreInformationEvent} />
                    <Button title="Précédent" fill={false} onClick={goToPrevStep} />
                </div>
            </div>
        );
    }

    const handleSelect = ({ description } : any) => () => {

        clearSuggestions();

        getGeocode({ address : description }).then((results) => getLatLng(results[0])).then(({ lat, lng }) => {
            console.log("📍 Coordinates: ", { lat, lng });
            setPosition({ lat, lng, adress : description });
            setValue(description);
        }).catch((error) => {
            console.log("😱 Error: ", error);
        });

        setOpen(false);
      };

    const renderSuggestions = () =>

        data.map((suggestion) => {

            const {
                structured_formatting: { main_text, secondary_text }
            } = suggestion;

            return (
                <AdresseListed adresse={main_text + " " + secondary_text} clickevent={handleSelect(suggestion)} />
            );
    });

    const handleLieuSearchUp = async (e:any) => {
        setValue(e.target.value);
        setOpen(true);

        if (e.target.value === "")
        {
            setOpen(false);
        }
    }

    const step_4 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 4</p>
                <p>Informations complémentaires</p>
                <div className="create-form step-4-form">
                    <Input title="Prix à l’heure" placeholder="Ex. 20" value={inputs.price} onChanged={setInput} type="text" name="price" icon={<Euro />} />
                    <div className='setp_4_drop_wrapper'>
                        <p>Votre adresse</p>
                        <input value={value} onChange={handleLieuSearchUp} placeholder='Adresse' />
                        { open && <div className='setp_4_drop_items_wrapper'>
                            { status === "OK" && renderSuggestions() }
                        </div> }
                    </div>
                    <Drop title="Rayon d’activité maximum" choice={Data.rayon} selected={inputs.rayon} onClick={handleChangeByOne} name="rayon" />
                    <Drop title="Souhaitez-vous masquer votre numéro ?" choice={Data.hide} selected={inputs.hide} onClick={handleChangeByOne} name="hide" />
                    <Button title="Suivant" fill={true} onClick={handlePayEvent} />
                    <Button title="Précédent" fill={false} onClick={goToPrevStep} />
                </div>
            </div>
        );
    }

    const handleChange = function(event : any, newValue : any)
    {
        setTime(newValue);
    };

    const step_5 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 5</p>
                <p>Tarification de votre annonce</p>
                <div className="create-form step-5-form">
                    <div>
                        <p>Temps de mise en ligne</p>
                        <p>{`${ time } jours`}</p>
                    </div>
                    <PrettoSlider min={1} max={31} value={time} onChange={handleChange} />
                    <div>
                        <p>Estimation du prix de votre annonce</p>
                        <p>{`Environ ${ (3.59 * time).toFixed(2).toString() } € pour ${ time } jour`}s</p>
                    </div>
                    <p>Vous serez débité après la fin de la période de votre annonce. Vous aurez la possibilité de renouveler votre période.</p>
                    <Button title="Suivant" fill={true} onClick={goToNextStep} />
                    <Button title="Précédent" fill={false} onClick={goToPrevStep} />
                </div>
            </div>
        );
    }

    const step_6 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 6</p>
                <p>On y va ?</p>
                <div className="create-form step-6-form">
                    <p>Votre annonce</p>
                    <p>Si vous pensez que tout est bon, vous pouvez dès à présent publier votre annonce.</p>
                    <Button title={ id !== undefined ? "Publier les modifications" : "Publier mon annonce"} fill={true} onClick={ id !== undefined ? handleUpdate : handleFinishEvent} />
                    <Button title="Non, je souhaite la modifier" fill={false} onClick={handleModifyEvent} />
                </div>
            </div>
        );
    }

    const step_7 = function()
    {
        return (
            <div className="step-wrapper">
                <p>CRÉER UNE ANNONCE - ÉTAPE 7</p>
                <p>Votre annonce est en ligne</p>
                <div className="create-form step-7-form">
                    <Button title="Voir mon annonce" fill={true} onClick={handleSeeMyAdsEvent} />
                    <Button title="Retour à l’accueil" fill={false} onClick={handleGoToHomeEvent} />
                </div>
            </div>
        );
    }

    const displayStep = function()
    {
        switch (step) {
            case 1: return step_1();
            case 2: return step_2();
            case 3: return step_3();
            case 4: return step_4();
            case 5: return step_5();
            case 6: return step_6();
            case 7: return step_7();
            default: return step_1();
        }
    }

    return (
        <div className="create-view-wrapper">
            { displayStep() }
        </div>
    );
}

export default Create;