interface AdresseListedProps
{
    adresse : String
    clickevent : any
}

const AdresseListed = function({ adresse, clickevent } : AdresseListedProps) : JSX.Element
{
   const handleClick = (e : any) =>  {
        clickevent(adresse);
   }
   
    return (
        <div className="adresselisted" onClick={handleClick}>
            { adresse }
        </div>
    );
}

export default AdresseListed;