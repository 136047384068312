import { Switch } from "react-router-dom";

import { useContext } from "react";

import { UserContext } from "../core/providers/user";

import Login from "../views/auth/login/login";
import Register from "../views/auth/register/register";
import Reset from "../views/auth/reset/reset";
import Information from "../views/auth/information/information";

import Guard from "./guard";

function AuthRoute()
{
    const { user } = useContext(UserContext);

    return (
        <Switch>
            <Guard path="/auth/login" component={<Login />} logged={user === null} />
            <Guard path="/auth/register" component={<Register />} logged={user === null} />
            <Guard path="/auth/reset" component={<Reset />} logged={user === null} />
            <Guard path="/auth/information" component={<Information />} logged={user === null} />
        </Switch>
    );
}

export default AuthRoute;