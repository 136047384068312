import './chatHeader.css';

import { useContext, useEffect, useState } from 'react';

import Avatar from '../avatar/avatar';

import { ReactComponent as More } from '../../assets/chat/more.svg';

import Grade from '../grade/grade';

import { ReactComponent as Arrow } from '../../assets/icons/arrow-left.svg';

import { IChatStepView } from '../../views/home/messages/messages';


import ChatModal from '../../modals/chat/chat';

import { IChannel, IUser } from '../../core/models/model';
import Firestore from '../../core/services/firestore';
import { UserContext } from '../../core/providers/user';
import Factory from '../../core/services/factory';

interface IChatHeaderProps
{
    goBack : Function
    channel : IChannel | null,
    setSelected : Function
}

const ChatHeader = function({ goBack, channel, setSelected } : IChatHeaderProps) : JSX.Element
{
    // const history = useHistory();

    const [open, setOpen] = useState<boolean>(false);

    const [to, setTo] = useState<IUser | null>(null);

    const { user } = useContext(UserContext);

    // const handleCommentEvent = function() : void
    // {
    //     if (channel === null)
    //     {
    //         return;
    //     }

    //     history.push(`/home/search/ads/${ channel!.id }`);

    //     window.scrollTo({ top : 500, behavior : 'smooth' });
    // }

    useEffect(() =>
    {
        if (channel === null)
        {
            return;
        }

        const getToUser = async function() : Promise<void>
        {
            var other = null;

            if (channel!.parent === user!.id)
            {
                const uid = channel.users.filter((u) => u !== user!.id);

                other = await Firestore.getUserDocumentFromFirestore(uid[0]);
            }
            else
            {
                other = await Firestore.getUserDocumentFromFirestore(channel!.parent);
            }

            setTo(Factory.createUserFromFirebase(other.data()));
        }

        getToUser();

    }, [channel]);
    
    const handleOpenEvent = function() : void
    {
        setOpen(!open);
    }

    const handleSeeAdsEvent = function() : void
    {
        goBack(IChatStepView.INFORMATION);
    }

    return (
        <div className="chat-header">
            <div className="chat-header-info">
                <button onClick={ () => goBack(IChatStepView.CHANNELS) }>
                    <Arrow />
                </button>
                { to && <Avatar src={to.avatar} />}
                <div className="chat-header-data">
                    { to && <p>{ to.name }</p> }
                </div>
            </div>
            <div className="chat-header-menu">
                <div>
                    <button onClick={ () => handleOpenEvent() }>
                        <More />
                    </button>
                    <div className="menu-text">
                        <p onClick={ () => handleOpenEvent() }>Actions</p>
                        <p onClick={ () => handleSeeAdsEvent() }>Voir l’annonce</p>
                    </div>
                    { open && <ChatModal setOpen={setOpen} channel={channel!} setSelected={setSelected} /> }
                </div>
            </div>
        </div>
    );
}

export default ChatHeader;