import './message.css';

import React from 'react';

import Avatar from '../avatar/avatar';

import { ReactComponent as More } from '../../assets/chat/more.svg';

import { IChannel, IMessage, IUser } from '../../core/models/model';

import DeleteIcon from '@material-ui/icons/Delete';
import { database } from '../../core/services/firebase';

interface IMessageProps
{
    observer : any
    message : IMessage
    user : IUser | null
    channel : IChannel,
    callbackOpenMonitor : Function
}

class Message extends React.Component<IMessageProps, { open : boolean }>
{
    constructor(props : IMessageProps)
    {
        super(props);

        this.state = { open : false }

        this.update = this.update.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.handleImageEvent = this.handleImageEvent.bind(this);
    }

    public componentDidMount()
    {
        this.props.observer.add(this.update);
    }

    public componentWillUnmount()
    {
        this.props.observer.remove(this.update);
    }

    public update(id : string) : void
    {
        if (id === this.props.message.id)
        {
            this.setState({ open : !this.state.open });
        }
        else
        {
            this.setState({ open : false });
        }
    }

    private handleOptionsEvent() : void
    {
        this.props.observer.update(this.props.message.id);
    }

    private async handleDeleteEvent() : Promise<void>
    {
        try
        {
            await database.collection('channels').doc(this.props.channel.id).collection('chat').doc(this.props.message.id).delete();

            this.setState({ open : false });
        }
        catch (error : any)
        {
            console.log(error);
        }
    }

    private handleImageEvent() : void
    {
        if (this.props.message.type !== "image")
        {
            return;
        }
        
        this.props.callbackOpenMonitor(this.props.message.image);
    }

    public render()
    {
        const who : boolean = this.props.message.parent === this.props.user?.id ? true : false;

        return (
            <div className={`message-item ${ who ? "message-item-me" : "message-item-other" }`}>
                <Avatar src={this.props.user?.avatar} />
                <div className={`message-content ${ who ? "message-content-me" : "message-content-other" }`} onClick={this.handleImageEvent}>
                   { this.props.message.type === "text" && <p>{ this.props.message.message }</p> }
                   { this.props.message.type === "image" && <img src={this.props.message.image } alt="picture" /> }
                </div>
                <div className="option-message">
                   { who && <More onClick={ () => this.handleOptionsEvent() } /> }
                    { this.state.open && <DeleteIcon onClick={this.handleDeleteEvent} /> }
                </div>
            </div>
        );
    }
}

export default Message;