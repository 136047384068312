import { useContext, useState } from "react";

import Button from "../../../components/button/button";
import Input from "../../../components/input/input";

import HeaderSettingsView from "./header";

import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';

import useForm from "../../../utils/useForm";

import { IProfileViewProps } from "./profile";

import { database } from "../../../core/services/firebase";

import { UserContext } from "../../../core/providers/user";
import { useToasts } from "react-toast-notifications";
import Storage from "../../../core/services/storage";

const AccountProfile = function({ setStep } : IProfileViewProps) : any
{
    const [loading, setLoading] = useState<boolean>(false);
    
    const { user, setUser } = useContext(UserContext);

    const { inputs, setInput } = useForm({ name : user!.name,  mail : user!.email });

    const { addToast } = useToasts();

    const [file, setFile] = useState<any>(null);
    
    const handleSaveInformationEvent = async function() : Promise<void>
    {
        if (inputs.name === "" || inputs.mail === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setLoading(true);

        try
        {
            var url : string = "";

            if (file !== null)
            {
                url = await Storage.addToStorage(`/users${user!.id}/`, file);

                user!.avatar = url;
            }

            await database.collection('users').doc(user!.id).update({ name : inputs.name, email : inputs.mail, avatar : url ? url : user!.avatar });

            user!.name = inputs.name;
            user!.email = inputs.mail;

            setUser(user);

            addToast('Vos informations ont bien été mis à jour.', { appearance: 'success', autoDismiss : true });
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }
    
    const handleSelectFileEvent = function(event : any) : void
    {
        event.preventDefault();

        if (event.target.files !== null && event.target.files.length > 0)
        {
            let reader = new FileReader();
        
            reader.readAsDataURL(event.target.files[0]);

            reader.onload = () => {
                setFile(reader.result);
            };
        }
    }

    return (
        <div className="profile-menu-view">
            <HeaderSettingsView title="Informations personnelles" setStep={setStep} />
            <div className="profile-business invisible-scrollbar">
                <Input title="Nom et prénom" value={inputs.name} onChanged={setInput} name="name" />
                <Input title="Adresse email" value={inputs.mail} onChanged={setInput} name="mail" type="email" />
                <div className="profile-business-upload">
                    <p>Photo de profile</p>
                    <label htmlFor="fileInputProfile">
                        { file && <img src={file} alt="ad" /> }
                        <div>
                            <Upload />
                            { file === null && <p>Cliquez ici pour choisir une photo</p> }
                        </div>
                        <input type="file" id="fileInputProfile" onChange={handleSelectFileEvent} accept="image/jpg, image/png, image/jpeg" />
                    </label>
                </div>
                <Button title={ loading ? "Chargement..." : "Sauvegarder"} fill={true} onClick={ () => handleSaveInformationEvent() } />
            </div>
        </div>
    );
}

export default AccountProfile;