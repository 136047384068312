import { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { UserContext } from "../../../core/providers/user";

import { database } from "../../../core/services/firebase";
import { setApplicationModal } from "../../../core/slice/application";
import { useAppDispatch } from "../../../utils/useStore";

import HeaderSettingsView from "./header";

import { IProfileViewProps } from "./profile";

const MoreProfile = function({ setStep } : IProfileViewProps) : any
{
    const { user, setUser } = useContext(UserContext);

    const dispatch = useAppDispatch();

    const { addToast } = useToasts();

    const enableDisableAccount = async function() : Promise<void>
    {
        try
        {
            if (user!.visibility === false)
            {
                dispatch(setApplicationModal({ index : 2, props : null }));
                
                await database.collection('users').doc(user!.id).update({ visibility : !user!.visibility });

                setUser({ ...user, visibility : user!.visibility });

                dispatch(setApplicationModal({ index : null, props : null }));

                addToast("Votre compte a été activée.", { appearance: 'success', autoDismiss : true });
            }
            else
            {
                dispatch(setApplicationModal({ index : 6, props : null }));
            }
        }
        catch (error)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }
    }

    const deleteAccount = async function() : Promise<void>
    {
        dispatch(setApplicationModal({ index : 2, props : null }));
    }

    return (
        <div className="profile-menu-view">
            <HeaderSettingsView title="Plus" setStep={setStep} />
            <div className="profile-more-item invisible-scrollbar">
                { user!.visibility ? <p>Désactiver mon compte</p> : <p>Activer mon compte</p> }
                <div>
                    <p>Souhaitez-vous vraiment désactiver votre compte HappyBati ?</p>
                    <button onClick={ () => enableDisableAccount() }>{ user!.visibility ? "Désactiver" : "Activer" }</button>
                </div>
            </div>
            <div className="profile-more-item">
                <p>Supprimer mon compte</p>
                <div>
                    <p>Souhaitez-vous vraiment supprimer votre compte HappyBati ?</p>
                    <button onClick={ () => deleteAccount() }>Supprimer</button>
                </div>
            </div>
        </div>
    );
}

export default MoreProfile;