import './chatInput.css';

import Picker from 'emoji-picker-react';

import { ReactComponent as Picture } from '../../assets/chat/picture.svg';
import { ReactComponent as Smile } from '../../assets/chat/smile.svg';
import { ReactComponent as Plane } from '../../assets/chat/plane.svg';
import { ReactComponent as Remove } from '../../assets/icons/remove.svg';

import Avatar from '../avatar/avatar';

import { database } from '../../core/services/firebase';

import { useContext, useEffect, useRef, useState } from 'react';

import { UserContext } from '../../core/providers/user';

import { GridSpinner } from "react-spinners-kit";

import Storage from '../../core/services/storage';

import { IChannel, ITyping } from '../../core/models/model';
import firebase from 'firebase';
import { useToasts } from 'react-toast-notifications';
import Firestore from '../../core/services/firestore';

interface IChatInputProps
{
     channel : IChannel | null
}

const ChatInput = function({ channel } : IChatInputProps) : JSX.Element
{
    const [message, setMessage] = useState<string>("");

    const { user } = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);

    const [file, setFile] = useState<any>(null);

    var node = useRef<any>();

    const { addToast } = useToasts();

    const [typings, settypings] = useState<ITyping[]>([]);

    const [typeState, setTypeState] = useState<boolean>(true);

    useEffect(() => {

        window.addEventListener('mousedown', handleCloseEvent, false);

        return () => { window.removeEventListener('mousedown', handleCloseEvent, false); }

    }, []);

    useEffect(() => {

        if (channel === null)
        {
            return;
        }

        const unsubscibe = database.collection('channels').doc(channel!.id).collection("typings").doc("typings").onSnapshot(function(snapshot)
        {
            const data = snapshot.data();

            if (data === undefined)
            {
                settypings([]);
            }
            else
            {
                settypings(data.typings.filter((u : ITyping) => u.uid !== user!.id));
            }
        });

        return () => { unsubscibe(); }

    }, [channel]);

    const handleCloseEvent = function(event : any) : void
    {
        if (node && !node.current.contains(event.target))
        {
            setOpen(false);
        }
    }
    
    const sendMessage = async function() : Promise<void>
    {
        try
        {
            setLoading(true);

            await database.collection('channels').doc(channel!.id).update({ last : message });

            await database.collection('channels').doc(channel!.id).collection('chat').add({ message : message, parent : user!.id, avatar : user?.avatar, type : 'text', image : "", date : firebase.firestore.Timestamp.fromDate(new Date()) });

            setLoading(false);

            setMessage("");
        }
        catch (error : any)
        {
            console.log(error);
        }
    }

    const sendImage = async function() : Promise<void>
    {
        try
        {
            setLoading(true);

            const url = await Storage.addToStorage(`channels/messages/${channel!.id}`, file!);

            await database.collection('channels').doc(channel!.id).collection('chat').add({ message : "", parent : user!.id, avatar : user?.avatar, type : 'image', image : url, date : firebase.firestore.Timestamp.fromDate(new Date()) });

            setLoading(false);

            setFile(null);
        }
        catch (error : any)
        {
            console.log(error);
        }
    }

    const onEmojiClick = function(event : any, emojiObject : any)
    {
        setMessage(message + emojiObject.emoji);
    };

    const handleOpenEvent = function() : void
    {
        setOpen(!open);
    }

    const handleSelectFileEvent = function(event : any) : void
    {
        event.preventDefault();

        if (event.target.files[0] === null)
        {
            return;
        }

        if (event.target.files && event.target.files.length > 0)
        {
            let reader = new FileReader();
        
            reader.readAsDataURL(event.target.files[0]);

            reader.onload = () => {
                setFile(reader.result);
            };
        }
    }

    const handleSubmit = function() : void
    {
        if (channel?.block === true)
        {
            setMessage("");
            
            addToast("L'utilisateur est bloqué. débloquer le pour communiquer avec lui.", { appearance: 'error', autoDismiss : true });

            return;
        }
        else if (message === "" && file === null)
        {
            return;
        }
        else if (file === null)
        {
            const tmp = typings.filter(u => u.uid !== user?.id);
            
            database.collection('channels').doc(channel!.id).collection("typings").doc("typings").update({ typings: tmp });

            sendMessage();
        }
        else
        {
            sendImage();
        }
    }

    const handleInputTextChangeEvent = function(event : any) : void
    {
        if (file !== null)
        {
            return;
        }

        setMessage(event.target.value);
    }

    const handleTypings = async function(event : any) : Promise<void>
    {
        if (event.target.value.length <= 1 && typeState === false)
        {
            const tmp = typings.filter(u => u.uid !== user?.id);

            database.collection('channels').doc(channel!.id).collection("typings").doc("typings").update({ typings: tmp });

            setTypeState(true);
        }
        else if (event.target.value.length >= 1 && typeState === true)
        {
            database.collection('channels').doc(channel!.id).collection("typings").doc("typings").update({ typings: [...typings, { uid : user?.id, avatar : user?.avatar }] });

            setTypeState(false);
        }
    }

    const removeImage = function() : void
    {
        setFile(null);
    }

    return (
        <div className="chat-input">
            <div className='chat-typings'>
                { typings.map(t => <>
                    <Avatar src={channel?.avatar} />
                    <p>est en train d'écrire...</p></>)
                }
            </div>
            <div className="chat-input-top">
                <label htmlFor="fileInput"><Picture /></label>
                <input type="file" id="fileInput" onChange={handleSelectFileEvent} accept="image/jpg, image/png, image/jpeg" />
                <div className="chat-input-top-image">
                    { file && <div>
                        <img src={file} alt="selected picture" />
                        <Remove onClick={ () => removeImage() }/>
                    </div> }
                </div>
                <div className="chat-input-top-emoji" ref={node}>
                    { open && <Picker onEmojiClick={onEmojiClick} /> }
                </div>
                <Smile onClick={ () => handleOpenEvent() } />
            </div>
            <div className="chat-input-bottom">
                <Avatar src={user?.avatar} />
                <input onKeyDown={handleTypings} placeholder={file ? "" : "Tapez votre message.."} value={message} onChange={ (event) => handleInputTextChangeEvent(event) } />
                <button onClick={ () => handleSubmit() }>
                   { loading === false ? <><Plane /><p>Envoyer</p></> : <GridSpinner /> }
                </button>
            </div>
        </div>
    ); 
}

export default ChatInput;