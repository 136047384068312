import { createSlice } from '@reduxjs/toolkit';

import type { IRootState, SearchState } from '../services/store';

const initialState : SearchState =
{
    typesMaterials : null,
    typesHumans : null,
    myAds : { enable : null, disable : null }
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        fillTypesList : (state, actions) => {
            
            if (actions.payload.type === "humains")
            {
                state.typesHumans = actions.payload.data;
            }
            else
            {
                state.typesMaterials = actions.payload.data;
            }

        },
        fillMyAdsEnableList : (state, actions) => {
            state.myAds.enable = actions.payload;
        },
        fillMyAdsDisableList : (state, actions) => {
            state.myAds.disable = actions.payload;
        },
        clear : (state) => {
            state.myAds.disable = null;
            state.myAds.enable = null;
            state.typesHumans = null;
            state.typesMaterials = null;
        },
        clearTypes : (state) => {
            state.typesHumans = null;
            state.typesMaterials = null;
        }
    }
});

export const { fillTypesList, fillMyAdsEnableList, fillMyAdsDisableList, clear, clearTypes } = searchSlice.actions;

export const selectSearchTypes = (state : IRootState) => state.search;
export const selectSearchTypesMaterials = (state : IRootState) => state.search.typesMaterials;
export const selectSearchTypesHumains = (state : IRootState) => state.search.typesHumans;
export const selectSearchMyAds = (state : IRootState) => state.search.myAds;