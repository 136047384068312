import './types.css';

import { useContext, useEffect, useRef } from 'react';

import { useHistory } from 'react-router';

import Firestore from '../../core/services/firestore';

import Type from '../../components/type/type';

import { ReactComponent as Next } from '../../assets/icons/types-next.svg';

import { useAppDispatch, useAppSelector } from '../../utils/useStore';

import { setApplicationModal } from '../../core/slice/application';

import { fillTypesList, selectSearchTypesHumains, selectSearchTypesMaterials } from '../../core/slice/search';

import { IAd } from '../../core/models/model';
import { UserContext } from '../../core/providers/user';
import { DraftsSharp } from '@material-ui/icons';

interface ITypesProps
{
    title : string
    type : "matériels" | "humains"
}

function Types({ title, type } : ITypesProps)
{
    const history = useHistory();

    const application = useAppSelector(type === "humains" ? selectSearchTypesHumains : selectSearchTypesMaterials);

    const dispatch = useAppDispatch();
    
    const { user } = useContext(UserContext);

    const last = useRef<IAd>();

    useEffect(() => {

        const fetchTypes = async function()
        {
            dispatch(setApplicationModal({ index : 1, props : null }));

            const data : IAd[] = await Firestore.getAdsFromFirestore(3, type, setRef);

            if (data.length < 3)
            {
                setRef(null);
            }
            
            dispatch(fillTypesList({ data : data, type : type }));

            dispatch(setApplicationModal({ index : null, props : null }));
        }

        if (application === null)
        {
            fetchTypes();
        }

    }, [user, application]);

    const handleMoreEvent = function()
    {
        history.push(`/home/search/${type}`);

        window.scrollTo({ top : 0, behavior : 'smooth' });
    }

    const setRef = function(doc : any) : void
    {
        last.current = doc;
    }

    const fetchMoreAdsEvent = async function() : Promise<void>
    {
        if (application === null || last === null)
        {
            return;
        }

        dispatch(setApplicationModal({ index : 1, props : null }));

        const data : IAd[] = await Firestore.getAdsFromFirestoreWithRef(3, type, last, setRef);

        const new_list : IAd[] = application.concat(data);
            
        dispatch(fillTypesList({ data : new_list, type : type }));

        document.getElementById('list-ad')!.scrollBy(2000, 0);

        dispatch(setApplicationModal({ index : null, props : null }));
    }

    if (application === null || application?.length <= 0)
    {
        return <></>
    }

    return (
        <div className="types-wrapper">
            <div className="types-header">
                <p>{ title }</p>
                <p onClick={handleMoreEvent}>En voir plus</p>
            </div>
            <div className={`types-list-wrapper ${ last.current !== null && "types-list-wrapper-more"}`}>
                <div id="list-ad">
                    { application && application.map((item, index) => <Type last={index < application.length - 1 ? true : false} key={index} type={item} /> ) }
                </div>
                { (application.length >= 3 && last.current !== null) && <button onClick={fetchMoreAdsEvent}>
                    <Next />
                </button> }
            </div>
        </div>
    );
}

export default Types;