import './register.css';

import { useState } from 'react';

import { useHistory } from 'react-router';

import useAuth from '../../../utils/useAuth';
import useForm from '../../../utils/useForm';

import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import Image from '../../../assets/auth/register.png';

import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';

import { useToasts } from 'react-toast-notifications';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';

import Checkbox from '@mui/material/Checkbox';

function Register()
{
    const [loading, setLoading] = useState<boolean>(false);

    const [step, setStep] = useState<number>(0);

    const { register } = useAuth();
    
    const { inputs, setInput, handleChangeByOne } = useForm({ email : "", password : "", name : "", vpassword : "", checked : false });

    const history = useHistory();

    const [file, setFile] = useState<any>(null);

    const { addToast } = useToasts();

    const handleSubmitEvent = async function() : Promise<void>
    {
        if (file === null)
        {
            addToast("Veuillez choisir une image de profil.", { appearance: 'error', autoDismiss : true });

            return;
        }

        if (inputs.checked === false)
        {
            addToast("Vous devez accepter nos conditions générales.", { appearance: 'error', autoDismiss : true });

            return;
        }

        setLoading(true);

        try
        {
            await register(inputs, file.image);
        }
        catch (error : any)
        {
            addToast(error, { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    const handleLoginEvent = async function() : Promise<void>
    {
        history.push('/auth/login');
    }

    const handleNextStepEvent = function()
    {
        if (inputs.email === "" || inputs.password === "" || inputs.name === "" || inputs.vpassword === "")
        {
            addToast("Veuillez remplir tous les champs comme demandé.", { appearance: 'error', autoDismiss : true });
        }
        else if (inputs.password !== inputs.vpassword)
        {
            addToast("les mots de passe ne correspondent pas.", { appearance: 'error', autoDismiss : true });
        }
        else
        {
            setStep(1);
        }
    }

    const handlePrevStepEvent = function()
    {
        setStep(0);
    }

    const handleSelectFileEvent = function(event : any) : void
    {
        event.preventDefault();

        if (event.target.files !== null && event.target.files.length > 0)
        {
            let reader = new FileReader();
        
            reader.readAsDataURL(event.target.files[0]);

            reader.onload = () => {
                setFile({ image : reader.result, name : event.target.files[0].name });
            };
        }
    }

    const handleCloseEvent = function() : void
    {
        history.push('/home/search');
    }

    const handleChange = function() : void
    {
        handleChangeByOne("checked", !inputs.checked);
    }

    const uploadForm = function()
    {
        return (
            <>
                <div className="register-upload-profile-wrapper">
                    <p>Photo de profil</p>
                    <label htmlFor="fileInput">
                        <Upload />
                        <p>Cliquez ici pour choisir une photo</p>
                        <input type="file" id="fileInput" onChange={handleSelectFileEvent} accept="image/jpg, image/png, image/jpeg" />
                        <p>{ file && file.name }</p>
                    </label>
                </div>
                <div className="cgvu-wrapper">
                    <Checkbox checked={inputs.checked} onChange={handleChange} />
                    <p>J’accepte les <a href='http://localhost:3000/cgv' target="_blank">CGVU</a> de HappyBati.fr</p>
                </div>
                <Button title="Inscription" fill={true} onClick={handleSubmitEvent} loading={loading} />
                <Button title="Précédent" fill={false} onClick={handlePrevStepEvent} />
            </>
        );
    }

    const informationForm = function()
    {
        return (
            <>
                <Input name="name" title="Nom et prénom*" value={inputs.name} onChanged={setInput} placeholder="John Doe" type="text" />
                <Input name="email" title="Adresse email*" value={inputs.email} onChanged={setInput} placeholder="john.doe@gmail.com" type="email" />
                <Input name="password" title="Mot de passe*" value={inputs.password} onChanged={setInput} placeholder="Mot de passe" type="password" icon={<Eye />} />
                <Input name="vpassword" title="Confirmation mot de passe*" value={inputs.vpassword} onChanged={setInput} placeholder="Mot de passe" type="password" icon={<Eye />} />
                <Button title="Suivant" fill={true} onClick={handleNextStepEvent} loading={loading} />
                <Button title="Retour à l’interface de connexion" fill={false} onClick={handleLoginEvent} />
            </>
        );
    }

    return (
        <div className="register-view-wrapper">
            <div className="auth-image-wrapper">
                <img src={Image} alt="login" />
            </div>
            <div className="auth-right-scroll-wrapper">
                <div className="auth-right-wrapper">
                    <div className="auth-header-wrapper">
                        <p>C’EST PARTI !</p>
                        <p>Inscrivez-vous</p>
                        <Close onClick={handleCloseEvent}/>
                    </div>
                    <div className="auth-form-wrapper">
                        { step === 0 ? informationForm() : uploadForm() }
                    </div>
                    { step === 0 && <p className="register-information">*Les champs sont obligatoires</p> }
                </div>
            </div>
        </div>
    );
}

export default Register;