import { useState } from "react";

const useObserver = function()
{
    const [observables, setObservables] = useState<Function[]>([]);

    const add = function(observable : Function) : void
    {
        setObservables((observables) => [...observables, observable]);
    }

    const remove = function(observable : Function) : void
    {
        setObservables((observables) => observables.filter(callback => callback !== observable));
    }

    const update = function(id : string) : void
    {
        observables.forEach((callback : Function) => callback(id));
    }

    return { add, remove, update };
}

export default useObserver;