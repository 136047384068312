import './ads.css';

import { useContext, useEffect } from 'react';

import { UserContext } from '../../../core/providers/user';

import Firestore from '../../../core/services/firestore';

import Annonce from '../../../components/myAds/myAds';

import useDevice from '../../../utils/useDevice';

import { useAppDispatch, useAppSelector } from '../../../utils/useStore';

import { fillMyAdsDisableList, fillMyAdsEnableList, selectSearchMyAds } from '../../../core/slice/search';

import { setApplicationMenuIndex, setApplicationModal } from '../../../core/slice/application';

import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import firebase from 'firebase';

function MyAds() {
    const { user } = useContext(UserContext);

    const isDesktop = useDevice();

    const application = useAppSelector(selectSearchMyAds);

    const dispatch = useAppDispatch();

    const history = useHistory();

    const { addToast } = useToasts();

    useEffect(() => {

        const fetchEnable = async function () {
            dispatch(setApplicationModal({ index: 1, props: null }));

            const data = await Firestore.getUserAdsFromFirestore(user!.id!);

            dispatch(fillMyAdsEnableList(data));

            dispatch(setApplicationModal({ index: null, props: null }));
        }

        if (application.enable === null) {
            fetchEnable();
        }

        const fetchDisable = async function () {
            dispatch(setApplicationModal({ index: 1, props: null }));

            const data = await Firestore.getUserFinishedAdsFromFirestore(user!.id!);

            dispatch(fillMyAdsDisableList(data));

            dispatch(setApplicationModal({ index: null, props: null }));
        }

        if (application.disable === null) {
            fetchDisable();
        }

    }, []);


    const handleCreateEvent = async function (): Promise<void> {
        var test = firebase.functions().httpsCallable('getUserCard');

        dispatch(setApplicationModal({ index: 1, props: null }));

        const result = await test({ customerID: user!.customerID });

        dispatch(setApplicationModal({ index: null, props: null }));

        if (user!.companie === undefined && result.data === false) {
            dispatch(setApplicationMenuIndex(3));

            addToast("Vous devez enregister une entreprise ainsi une carte bancaire pour pouvoir poster une annonce.", { appearance: 'info', autoDismiss: true, autoDismissTimeout: 6000 });

            history.push('/home/profile');
        }
        else if (user!.companie === undefined && result.data === true) {
            dispatch(setApplicationMenuIndex(3));

            addToast("Vous devez enregister une entreprise pour pouvoir poster une annonce.", { appearance: 'info', autoDismiss: true, autoDismissTimeout: 6000 });

            history.push('/home/profile');
        }
        else if (user!.companie !== undefined && result.data === false) {
            dispatch(setApplicationMenuIndex(4));

            addToast("Vous devez enregister une carte bancaire pour pouvoir poster une annonce.", { appearance: 'info', autoDismiss: true, autoDismissTimeout: 6000 });

            history.push('/home/profile');
        }
        else {
            history.push('/home/create');
        }
    }

    return (
        <div className="my-ads-view-wrapper">
            <div className="my-ads-view-title">
                <p>{`Mes annonces (${application.enable ? application.enable.length : 0})`}</p>
                <button onClick={() => handleCreateEvent()}>
                    {isDesktop ? "Créer une nouvelle annonce" : "+"}
                </button>
            </div>
            {application.enable && application.enable.map((ad, index) => <Annonce key={index} ad={ad} />)}
            <div className="my-ads-view-title">
                <p>{`Annonces antérieures (${application.disable ? application.disable.length : 0})`}</p>
            </div>
            {application.disable && application.disable.map((ad, index) => <Annonce key={index} ad={ad} />)}
        </div>
    );
}

export default MyAds;