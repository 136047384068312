import './chat.css';

import { useEffect, useRef } from 'react';

import { useToasts } from 'react-toast-notifications';
import { database } from '../../core/services/firebase';

import { useAppDispatch } from '../../utils/useStore';
import { setApplicationModal } from '../../core/slice/application';
import { IChannel } from '../../core/models/model';
import { IReportType } from '../report/report';

interface IChatProps
{
    setOpen : Function
    channel : IChannel,
    setSelected : Function
}

const Chat = function({ setOpen, channel } : IChatProps) : JSX.Element
{
    var node = useRef<any>();

    const { addToast } = useToasts();

    const dispatch = useAppDispatch();

    useEffect(() => {

        window.addEventListener('mousedown', handleCloseEvent, false);

        return () => { window.removeEventListener('mousedown', handleCloseEvent, false); }

    }, []);

    const handleCloseEvent = function(event : any) : void
    {
        if (node && !node.current.contains(event.target))
        {
            setOpen(false);
        }
    }

    const handleDeleteEvent = async function() : Promise<void>
    {
        try
        {
            await database.collection('channels').doc(channel.id).delete();

            addToast("Conversation supprimée.", { appearance: 'success', autoDismiss : true });
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setOpen(false);
    }

    const handleReportEvent = function() : void
    {
        setOpen(false);

        dispatch(setApplicationModal({ index : 0, props : { document : channel.id, title : "Signaler une conversation", type : IReportType.CHANNEL } }));
    }

    const handleUnblockEvent = async function() : Promise<void>
    {
        try
        {
            await database.collection('channels').doc(channel.id).update({ block : false });
        }
        catch (error)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setOpen(false);
    }

    const handleChancelEvent = function() : void
    {
        setOpen(false);
    }

    return (
        <div className="chat-modal" ref={node}>
            <div>
                <p>Que faire ?</p>
                <button onClick={ () => handleChancelEvent() }>Annuler</button>
            </div>
            <div>
                <button onClick={ () => handleDeleteEvent() }>
                    Supprimer la conversation
                </button>
                { channel.block ? <button onClick={ () => handleUnblockEvent() }>
                    Débloquer
                </button> : <button onClick={ () => handleReportEvent() }>
                    Signaler et bloquer
                </button> }
            </div>
        </div>
    );
}

export default Chat;