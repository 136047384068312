import './information.css';

import { useState } from 'react';

import { useHistory } from 'react-router';

import Button from '../../../components/button/button';

import Image from '../../../assets/auth/create.png';

function Information()
{
    const [loading, setLoading] = useState<boolean>(false);

    const [step, setStep] = useState<number>(0);

    const history = useHistory();

    const handleCreateEvent = async function() : Promise<void>
    {
        setLoading(true);

        // make request here //

        setLoading(false);
    }
    
    const handleUploadEvent = function() : void
    {
        setLoading(true);

        // make upload request here //

        setLoading(false);
    }

    const handleNextEvent = function() : void
    {
        setStep(step + 1);
    }

    const handlePrevEvent = function() : void
    {
        setStep(step - 1);
    }

    const handleHomeEvent = function() : void
    {
        history.push('/home/search');
    }

    const handleCancelEvent = function() : void
    {
        history.push('/home/search');
    }
    
    return (
        <div className="create-view-wrapper">
            <div className="auth-image-wrapper">
                <img src={Image} alt="login" />
            </div>
            <div className="auth-right-scroll-wrapper">
                <div className="auth-right-wrapper">
                    <div className="auth-header-wrapper">
                        <p>CRÉEZ ET PUBLIEZ</p>
                        <p>Votre première annonce</p>
                    </div>
                    <div className="auth-form-wrapper">
                        <p className="reset-title">Informations complémentaires</p>
                        <p className="reset-information">Avant de créer votre première annonce, nous aurons besoin d’informations supplémentaires vous concernant.</p>
                        <Button title="Suivant" fill={true} onClick={handleNextEvent} />
                        <Button title="Annuler" fill={false} onClick={handleCancelEvent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Information;