import { Route, Redirect } from "react-router-dom";

interface IGuardProps
{
    component : JSX.Element
    path : string
    logged : boolean
}

function Guard({ component, path, logged } : IGuardProps)
{
    return (
        <Route path={path} render={() => (
            logged ? component : <Redirect to='/' />
        )} />
    );
}

export default Guard;