import './button.css';

interface IButtonProps
{
    title : string
    fill : boolean
    onClick : Function
    loading ? : boolean
}

function Button({ title, fill, onClick, loading } : IButtonProps)
{
    const style = fill ? "button-fill-wrapper" : "button-not-fill-wrapper";

    return (
        <button className={`button-wrapper ${style}`} onClick={ () => onClick() }>
            { loading ? <p>Chargement...</p> : <p>{ title }</p> }
        </button>
    );
}

export default Button;