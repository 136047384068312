import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const PrettoSlider = withStyles({
    root: {
      color: '#88888865',
      height: 3,
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: 'white',
      border: '4px solid #FA8E20',
      marginTop: -6,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    track: {
      height: 3,
      borderRadius: 4,
      backgroundColor: '#FA8E20',
    },
    rail: {
      height: 3,
      borderRadius: 4,
    }
})(Slider);

export default PrettoSlider;