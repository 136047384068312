import { useContext, useState } from "react";

import Button from "../../components/button/button";

import { setApplicationModal } from "../../core/slice/application";

import useModal from "../../utils/useModal";

import { useAppDispatch } from "../../utils/useStore";

import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { database } from "../../core/services/firebase";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../../core/providers/user";

const AccountState = function() : JSX.Element
{   
    const { user } = useContext(UserContext);
    
    const [loading, setLoading] = useState<boolean>(false);

    const { node } = useModal();

    const dispatch = useAppDispatch();

    const { addToast } = useToasts();
    
    const [visible, setVisible] = useState<boolean>(user!.visibility);

    const enableDisableAccount = async function() : Promise<void>
    {
        if (loading == true)
        {
            return;
        }

        setLoading(true);

        try
        {
            await database.collection('users').doc(user!.id).update({ visibility : !visible });

            if (!visible === true)
            {
                addToast("Votre compte a été activée.", { appearance: 'success', autoDismiss : true });
            }
            else
            {
                addToast("Votre compte a été désactivée.", { appearance: 'warning', autoDismiss : true });
            }

            setVisible(!visible);
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);

        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleCloseEvent = function() : void
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleCancelEvent = async function() : Promise<void>
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    return (
        <div className="modal-wrapper">
            <div className="delete-custom-modal-wrapper" ref={node}>
                <div>
                    <h2>Desactivation du compte</h2>
                    <Close onClick={ () => handleCloseEvent() } />
                </div>
                <p>Êtes-vous certain(e) de vouloir désactiver votre compte ?</p>
                <div>
                    <Button title="Annuler" fill={false} onClick={handleCancelEvent} loading={loading} />
                    <Button title={ loading ? "Chargement..." : visible ? "Desactiver" : "Activer" } fill={true} onClick={enableDisableAccount} loading={loading} />
                </div>
            </div>
        </div>
    );
}

export default AccountState;