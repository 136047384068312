import useDevice from "../../../utils/useDevice";

import { ReactComponent as Arrow } from '../../../assets/icons/arrow-left.svg';

const HeaderSettingsView = function({ title, setStep, more, moreClick } : any) : any
{
    const isDesktop = useDevice();
    
    return (
        <div className="profile-menu-header">
            { isDesktop === false && <button onClick={ () => setStep(0) }>
                <Arrow />
            </button> }
            <p id="header-title-profile">{ title }</p>
            { more && <p id="header-more-title-profile" onClick={ () => moreClick() }>{ more }</p> }
        </div>
    );
}

export default HeaderSettingsView;