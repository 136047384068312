import { createSlice } from '@reduxjs/toolkit';

import type { IRootState, ApplicationState } from '../services/store';

const initialState : ApplicationState =
{
    profile : { index : 0 },
    settings : {},
    modal : { index : null, props : null },
    logged : false
}

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers : {
        setApplicationMenuIndex : (state, actions) => {
            state.profile.index = actions.payload;
        },
        setApplicationSettings : (state, actions) => {
            state.settings = actions.payload;
        },
        setApplicationModal : (state, actions) => {
            state.modal.index = actions.payload.index;
            state.modal.props = actions.payload.props;
        },
        setApplicationLogged : (state, actions) => {
            state.logged = actions.payload;
        }
    }
})

export const { setApplicationMenuIndex, setApplicationSettings, setApplicationModal, setApplicationLogged } = applicationSlice.actions;

export const selectApplicationProfile = (state : IRootState) => state.application.profile;
export const selectApplicationSettings = (state : IRootState) => state.application.settings;
export const selectApplicationModal = (state : IRootState) => state.application.modal;
export const selectApplication = (state : IRootState) => state.application;