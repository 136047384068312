import firebase from "firebase";
import { useContext } from "react";
import { UserContext } from "../../../core/providers/user";
import { setApplicationModal } from "../../../core/slice/application";
import useDevice from "../../../utils/useDevice";

import { useAppDispatch } from "../../../utils/useStore";
import HeaderSettingsView from "./header";

import { IProfileViewProps } from "./profile";

const SettingsProfile = function({ setStep } : IProfileViewProps) : any
{
    const isDeskot = useDevice();

    const { user } = useContext(UserContext);

    const dispatch = useAppDispatch();

    const handleMoreEvent = async function() : Promise<void>
    {
        var test = firebase.functions().httpsCallable('updateCardToStripeCustomer');

        dispatch(setApplicationModal({ index: 1, props: null }));

        await test({ customerID : user!.customerID, url : "https://happybati.web.app/" }).then((result: any) => { window.location.href = result.data });

        dispatch(setApplicationModal({ index: null, props: null }));
    }

    return (
        <div className="profile-menu-view">
            <HeaderSettingsView title="Moyen de paiement" setStep={setStep} more={ isDeskot ? "Ajouter un moyen de paiement" : "Ajouter" } moreClick={handleMoreEvent} />
        </div>
    );
}

export default SettingsProfile;