import './search.css';

import SearchBar from '../searchBar/searchBar';

function Search()
{
    return (
        <section className="search-section">
            <p>BESOIN DE RESSOURCES POUR VOS CHANTIERS ?</p>
            <p>Trouvez ou proposez des moyens matériels & <br/> humains pour votre entreprise.</p>
            <SearchBar />
            <p>Peinture - Plomberie - Gros travaux - Charpente - Paysage - Électricité - Plaquiste</p>
        </section>
    );
}

export default Search;