import { useEffect, useRef } from "react";

import { setApplicationModal } from "../core/slice/application";

import { useAppDispatch } from "./useStore";

function useModal()
{
    var node = useRef<any>();

    const dispatch = useAppDispatch()

    useEffect(() => {

        document.addEventListener('mousedown', handleCloseEvent, false);

        return () => { document.removeEventListener('mousedown', handleCloseEvent, false); }

    }, []);

    const handleCloseEvent = function(event : any) : void
    {
        if (node && !node.current.contains(event.target))
        {
            dispatch(setApplicationModal({ index : null, props : null }));
        }
    }

    return { node };
}

export default useModal;